import { SxProps, Typography, useMediaQuery, useTheme } from '@mui/material'

import {
  StyledMainBox,
  StyledImageBox,
  StyledMarginBox,
  StyledOverlayBox,
  StyledTextBox,
  StyledTitleTypography,
  StyledInsightTypography,
} from './styled'

interface IProps {
  image: string
  title: string
  insight: React.ReactElement
  date: string
  sx?: SxProps
}

export function InsightCard({ image, title, insight, date, sx }: IProps) {
  const theme = useTheme()
  const lgAndUp = useMediaQuery(theme.breakpoints.up(1200))

  return (
    <StyledMainBox sx={sx}>
      <StyledImageBox className="image" image={image} />

      {lgAndUp && (
        <>
          <StyledMarginBox className="margin-box" />
          <StyledOverlayBox className="overlay" />
        </>
      )}

      <StyledTextBox className="text-box">
        <StyledTitleTypography className="title" variant="subtitle25SemiBold">
          {title}
        </StyledTitleTypography>

        <StyledInsightTypography
          className="content"
          variant="text16Regular"
          whiteSpace="pre-wrap"
        >
          {insight}
        </StyledInsightTypography>

        <Typography
          className="date"
          variant="text14Regular"
          display="block"
          color="text.secondary"
        >
          {date}
        </Typography>
      </StyledTextBox>
    </StyledMainBox>
  )
}
