import { SxProps, Box, Typography, useTheme } from '@mui/material'
import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { getCurrentBrowser } from '@shared/utils'

import { StyledOverlayBox, StyledContainer, StyledVideo } from './styled'

interface IProps {
  sx?: SxProps
}

const pathWebm =
  'https://sicav-resources.s3.eu-central-1.amazonaws.com/webm/Arbitas+Bg+webm.webm'

const pathMp4 =
  'https://sicav-resources.s3.eu-central-1.amazonaws.com/mp4/Arbitas+Bg+mp4.mp4'

export function Banner({ sx }: IProps) {
  const { t } = useTranslation('widgets')
  const theme = useTheme()
  const ref = useRef<HTMLVideoElement | null>(null)

  useEffect(() => {
    if (ref.current) {
      const browser = getCurrentBrowser()

      if (browser === 'Safari') {
        ref.current.setAttribute('muted', 'true')

        ref.current.setAttribute('playsinline', 'true')

        ref.current.setAttribute('autoPlay', 'true')

        // eslint-disable-next-line no-console
        ref.current.play().catch((error) => console.error(error))
      }
    }
  }, [ref.current])

  return (
    <Box sx={sx} height="100vh" position="relative" component="section">
      <StyledVideo
        ref={ref}
        poster="/image/poster.png"
        playsInline
        autoPlay
        loop
        preload="yes"
        muted
      >
        <source src={pathMp4} type="video/mp4" />
        <source src={pathWebm} type="video/webm" />
        Your browser does not support the video tag.
      </StyledVideo>

      <StyledOverlayBox />

      <StyledContainer maxWidth="lg">
        <Typography
          variant="h1"
          textAlign="center"
          letterSpacing={{ xs: '0.56px', md: 'initial' }}
          color={theme.palette.text.white}
          mt={{ xs: 25.5, md: 'initial' }}
          sx={{ position: 'relative', zIndex: 1 }}
        >
          {t('main-page.banner.title')}
        </Typography>
      </StyledContainer>
    </Box>
  )
}
