import { Box } from '@mui/material'
import { styled } from '@mui/material'

export const StyledImageBox = styled(Box)(({ theme }) => ({
  maxWidth: 570,
  paddingRight: 24,
  paddingLeft: 24,
  margin: '0 auto',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  borderRadius: theme.spacing(2.5),
  backgroundColor: theme.palette.mainBodyColors.purple,
}))
