import { Box, styled } from '@mui/material'

export const StyledBox = styled(Box)(({ theme }) => ({
  '& .splide__arrow': {
    width: 40,
    height: 40,
    top: 'initial',
    transform: 'initial',
    bottom: 20,
    backgroundColor: theme.palette.secondary.main,
    opacity: 'initial',
    transition: theme.transitions.create(['background-color'], {
      duration: theme.transitions.duration.standard,
    }),
    ':hover': {
      opacity: 'initial',
      backgroundColor: theme.palette.buttonHover,
    },
    svg: {
      fill: theme.palette.common.white,
    },
    '&--prev': {
      left: 0,
    },
    '&--next': {
      right: 'initial',
      left: 72,
    },
  },
  '& .splide__track': {
    marginBottom: theme.spacing(4),
  },
  '& .splide__pagination': {
    position: 'initial',
    padding: 0,
    marginBottom: 0,
    '& > li': {
      height: 2,
      flex: 1,
    },
    '&__page': {
      width: '100%',
      height: 2,
      margin: 0,
      display: 'block',
      borderRadius: 'initial',
      opacity: 'initial',
      backgroundColor: theme.palette.greyBlue.main,
      transition: theme.transitions.create(['background-color'], {
        duration: theme.transitions.duration.standard,
      }),
      '&.is-active': {
        transform: 'initial',
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
  [theme.breakpoints.between(1200, 1268)]: {
    width: 780,
    marginRight: 'auto',
    marginLeft: 'auto',
  },
  [theme.breakpoints.between(1010, 1199)]: {
    width: 960,
    marginRight: 'auto',
    marginLeft: 'auto',
  },
  [theme.breakpoints.between(670, 1009)]: {
    width: 600,
    marginRight: 'auto',
    marginLeft: 'auto',
  },
}))
