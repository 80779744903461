import { Grid, styled } from '@mui/material'

export const StyledGridItem = styled(Grid)<{ index?: number }>(
  ({ theme, index }) => ({
    display: 'flex',
    alignItems: 'center',
    order: 1,
    [theme.breakpoints.up('md')]: {
      order: index && index % 2,
    },
  })
)
