import { useMediaQuery, useTheme } from '@mui/material'

import { IMenuItem } from '../model'
import {
  StyledDivider,
  StyledLink,
  StyledTypography,
  StyledButton,
} from './styled'

export interface IProps extends IMenuItem {
  isTransparentBackground: boolean
}

export function MenuItem({
  disabled,
  navigationLink,
  isTransparentBackground,
  name,
}: IProps) {
  const theme = useTheme()
  const mdAndUp = useMediaQuery(theme.breakpoints.up('md'))

  const isCurrentPath = (navigationLink: string): boolean => {
    const mainPath = location.pathname.split('/')[1]
    const mainNavLink = navigationLink.split('/')[1]

    if (mainPath === mainNavLink) {
      return true
    }

    return false
  }

  return (
    <>
      <StyledLink
        href={!disabled ? navigationLink : '#'}
        sx={{ cursor: disabled ? 'default' : 'pointer' }}
      >
        <StyledButton disableTouchRipple disabled={disabled}>
          <StyledTypography
            variant={mdAndUp ? 'text16MediumUp' : 'text16Regular'}
            current={isCurrentPath(navigationLink)}
            isTransparentBackground={isTransparentBackground}
          >
            {name}
          </StyledTypography>
        </StyledButton>
      </StyledLink>

      {!mdAndUp && <StyledDivider />}
    </>
  )
}
