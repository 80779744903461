import { styled } from '@mui/material'

interface IProps {
  last: boolean
}

export const StyledValueTdBox = styled('div', {
  shouldForwardProp: (prop) => prop !== 'last',
})<IProps>(({ theme, last }) => ({
  height: '100%',
  padding: '28px 40px',
  ...(!last && { borderBottom: '1px solid' }),
  borderColor: theme.palette.grey.line,
}))
