import { styled } from '@mui/material'

export const Divider = styled('div')(({ theme }) => ({
  height: 1,
  marginTop: 20,
  marginBottom: 20,
  width: '50%',
  background: 'linear-gradient(90deg, #009EE0 0%, rgba(0, 158, 224, 0) 100%)',
  borderRadius: 100,
  [theme.breakpoints.up('md')]: {
    height: 2,
    width: '100%',
    marginTop: 32,
    marginBottom: 38,
  },
}))
