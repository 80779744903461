import { ToastContainer } from 'react-toastify'
// eslint-disable-next-line import/no-internal-modules
import 'react-toastify/dist/ReactToastify.css'

import RouterProvider from './router'
import ThemeProvider from './theme'

export function ComposeProviders() {
  return (
    <ThemeProvider
      children={
        <>
          <ToastContainer
            position="bottom-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
            style={{ fontSize: 16 }}
          />

          <RouterProvider />
        </>
      }
    />
  )
}
