import { Box, styled } from '@mui/material'

export const StyledBubble3 = styled(Box)<{ index?: number }>(
  ({ theme, index }) => ({
    display: 'none',
    background: theme.palette.background.violet,
    filter: 'blur(80px)',
    opacity: 0.9,
    width: 224,
    height: 126,
    position: 'absolute',
    left: index ? -20 : 114,
    bottom: index ? -36 : 0,
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  })
)
