import { Container, styled } from '@mui/material'

export const StyledContainer = styled(Container)(({ theme }) => ({
  '& div': {
    [theme.breakpoints.up('md')]: {
      width: 'calc(100% + 200px) !important',
      position: 'relative',
      right: 200,
      borderRadius: '0px 20px 20px 0px',
    },
  },
}))
