import { SxProps, Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { TRouteNamesPaths, routeNamesPaths } from '@shared/constants'
import { Map } from '@shared/ui'

import { StyledBox, StyledLink } from './styled'

interface ILinks {
  link: TRouteNamesPaths
  sx: SxProps
}

export function LinksList() {
  const { t } = useTranslation('features')

  const links: ILinks[] = [
    {
      link: routeNamesPaths.forStartups,
      sx: { order: { md: 4 } },
    },
    {
      link: routeNamesPaths.forInvestors,
      sx: { order: { md: 1 } },
    },
    {
      link: routeNamesPaths.subFund,
      sx: { order: { md: 0 } },
    },
    {
      link: routeNamesPaths.arbipayOnlineBank,
      sx: { order: { md: 3 } },
    },
    {
      link: routeNamesPaths.arbipointMarketplace,
      sx: { order: { md: 2 } },
    },
  ]

  return (
    <StyledBox component="ul">
      <Map
        collection={links}
        iterate={(link, index) => (
          <Box component="li" key={index}>
            <StyledLink href={link.link} sx={link.sx} key={index}>
              <Typography
                variant="text16Medium"
                whiteSpace="nowrap"
                textTransform="uppercase"
              >
                {t(`error.links titles.${index}` as never)}
              </Typography>
            </StyledLink>
          </Box>
        )}
      />
    </StyledBox>
  )
}
