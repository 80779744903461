import { Box, styled } from '@mui/material'

export const StyledMainBgBox = styled(Box)(({ theme }) => ({
  width: 'calc(100% + 48px)',
  height: '100%',
  marginLeft: theme.spacing(-3),
  position: 'relative',
  overflow: 'hidden',
  [theme.breakpoints.up('md')]: {
    overflow: 'initial',
  },
}))
