import { Box, styled } from '@mui/material'

export const StyledBox = styled(Box)(({ theme }) => ({
  width: 'calc(100% + 48px)',
  marginLeft: theme.spacing(-3),
  padding: '0px 24px 48px',
  position: 'relative',
  [theme.breakpoints.up('md')]: {
    width: '100%',
    padding: '60px 44px',
    marginLeft: theme.spacing(0),
    borderRadius: theme.spacing(2.5),
    backgroundColor: theme.palette.mainBodyColors.grayLight,
    overflow: 'hidden',
  },
}))
