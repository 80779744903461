import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

export const LevelTitle = styled(Typography)(({ theme }) => ({
  fontFamily: 'Inter',
  fontSize: 18,
  fontWeight: 600,
  color: theme.palette.text.white,

  [theme.breakpoints.up('md')]: {
    fontSize: 17,
  },
}))
