import {
  Box,
  Container,
  IconButton,
  Link,
  Stack,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { Squash as Hamburger } from 'hamburger-react'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'

import { useLocation } from 'react-router-dom'
import { NavBar } from '@features/app-bar'

import { useMobileMenu } from '@entities/app-bar'

import { routeNamesPaths } from '@shared/constants'

import { ReactComponent as FundLogo } from './assets/arbitas-logo-fund.svg'

import { LangMenu } from './components'
import MobileMenu from './mobile-menu'
import {
  StyledAppBar,
  StyledToolbar,
  StyledDrawer,
  StyledLogoBox,
} from './styled'

export default function AppBar() {
  const location = useLocation()

  const [isTransparentBackground, setIsTransparentBackground] =
    useState<boolean>(true)

  const showMobileMenu = useMobileMenu((state) => state.showMobileMenu)

  const setShowMobileMenu = useMobileMenu(
    (state) => state.setShowMobileMenu
  ) as Dispatch<SetStateAction<boolean>>

  const theme = useTheme()
  const mdAndUp = useMediaQuery(theme.breakpoints.up('md'))

  const [scrollPosition, setScrollPosition] = useState(0)

  const handleScroll = () => {
    const position = window.pageYOffset

    setScrollPosition(position)
  }

  useEffect(() => {
    if (
      mdAndUp &&
      location.pathname === routeNamesPaths.mainPage &&
      scrollPosition < window.innerHeight - 85
    ) {
      setIsTransparentBackground(true)
    } else {
      setIsTransparentBackground(false)
    }
  }, [mdAndUp, location.pathname, isTransparentBackground, scrollPosition])

  useEffect(() => {
    if (showMobileMenu) {
      document.body.setAttribute('style', 'overflow-y: hidden')
    }

    if (!showMobileMenu) {
      document.body.removeAttribute('style')
    }
  }, [showMobileMenu])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true })

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <>
      <StyledAppBar>
        <StyledToolbar
          disableGutters={true}
          isTransparentBackground={isTransparentBackground}
        >
          <Container maxWidth="lg">
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Link
                href="/"
                lineHeight={0}
                onClick={() => setShowMobileMenu(false)}
              >
                <StyledLogoBox
                  isTransparentBackground={isTransparentBackground}
                >
                  <FundLogo width="100%" />
                </StyledLogoBox>
              </Link>

              {mdAndUp && (
                <>
                  <NavBar isTransparentBackground={isTransparentBackground} />
                  <LangMenu isTransparentBackground={isTransparentBackground} />
                </>
              )}

              {!mdAndUp && (
                <Box mr={-1.5}>
                  <IconButton sx={{ p: 0 }}>
                    <Hamburger
                      toggled={showMobileMenu}
                      toggle={setShowMobileMenu}
                      color={theme.palette.text.primary}
                      size={24}
                    />
                  </IconButton>
                </Box>
              )}
            </Stack>
          </Container>
        </StyledToolbar>
      </StyledAppBar>

      <StyledDrawer
        transitionDuration={500}
        open={showMobileMenu && !mdAndUp}
        anchor="top"
        variant="temporary"
      >
        <MobileMenu />
      </StyledDrawer>
    </>
  )
}
