import { Typography, styled } from '@mui/material'

interface IProps {
  current: boolean
  variant: string
  isTransparentBackground: boolean
}

export const StyledTypography = styled(Typography, {
  shouldForwardProp: (prop) =>
    prop !== 'current' && prop !== 'isTransparentBackground',
})<IProps>(({ theme, current, isTransparentBackground }) => {
  const getColor = () => {
    if (isTransparentBackground) {
      return theme.palette.text.white
    }

    if (current) {
      return theme.palette.primary.main
    }

    return theme.palette.text.primary
  }

  return {
    position: 'relative',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    color: current ? theme.palette.primary.main : theme.palette.text.primary,
    transition: theme.transitions.create(['color'], {
      duration: theme.transitions.duration.standard,
    }),
    [theme.breakpoints.up('md')]: {
      paddingTop: 0,
      paddingBottom: 0,
      paddingRight: 0,
      paddingLeft: 0,
      color: getColor(),
      '&:hover:before': {
        opacity: 1,
      },
      '&:before': {
        content: "' '",
        display: 'block',
        position: 'absolute',
        bottom: -2,
        width: '100%',
        height: 2,
        opacity: current ? 1 : 0,
        background: `radial-gradient(50% 50.00% at 50% 50.00%, ${
          isTransparentBackground
            ? theme.palette.background.white
            : theme.palette.primary.main
        } 0%, ${theme.palette.radialLine} 100%)`,
        transition: theme.transitions.create(['opacity'], {
          duration: theme.transitions.duration.standard,
        }),
      },
    },
  }
})
