import {
  useTheme,
  Link,
  Stack,
  Typography,
  useMediaQuery,
  SxProps,
  Box,
} from '@mui/material'
import { useTranslation } from 'react-i18next'

import { CompanyAddress } from '@entities/company-address'

interface IProps {
  sx?: SxProps
}

export function ContactUs({ sx }: IProps) {
  const theme = useTheme()
  const mdAndUp = useMediaQuery(theme.breakpoints.up('md'))
  const { t } = useTranslation('widgets')

  return (
    <Box sx={sx} color={theme.palette.text.white}>
      <Typography
        variant={mdAndUp ? 'desktopFooterTitleUp' : 'mobileFooterTitleUp'}
        color="inherit"
        component="h6"
        mb={1.5}
      >
        {t('footer.Contact us')}
      </Typography>

      <Stack spacing={1} maxWidth={250}>
        <CompanyAddress />

        <Link
          href="mailto:sicav@arbitas.com"
          variant="text16Regular"
          sx={{ color: 'inherit' }}
        >
          sicav@arbitas.com
        </Link>

        {/* TODO: enable in future */}
        {/* <Box mt={4.5}>
            <Link href="#" lineHeight={0} rel="noopener">
              <Typography
                variant="text16Semibold"
                color={theme.palette.text.white}
              >
                {t('footer.Send enquiry')}
              </Typography>
            </Link>
          </Box> */}
      </Stack>
    </Box>
  )
}
