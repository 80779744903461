import { Box, styled } from '@mui/material'

export const StyledIconBox = styled(Box)(({ theme }) => ({
  height: 40,
  '& svg': {
    width: '100%',
    height: '100%',
    display: 'block',
    [theme.breakpoints.up('md')]: {
      width: 'initial',
      height: 'initial',
    },
  },
  [theme.breakpoints.up('md')]: {
    height: 'initial',
    paddingTop: theme.spacing(2.5),
    borderTop: '2px solid',
    borderColor: theme.palette.secondary.dark,
  },
}))
