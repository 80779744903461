import { Typography, styled } from '@mui/material'

export const StyledTypography = styled(Typography)(({ theme }) => ({
  fontStyle: 'italic',
  color: theme.palette.text.text,
  lineHeight: '20px',
  whiteSpace: 'pre-wrap',
  '& a': {
    wordBreak: 'break-all',
    color: theme.palette.text.text,
    ':hover': {
      textDecorationColor: theme.palette.text.text,
    },
  },
}))
