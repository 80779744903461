import { styled } from '@mui/material'

export const StyledLi = styled('li')(({ theme }) => ({
  width: '100%',
  height: 50,
  transition: theme.transitions.create(['background-color'], {
    duration: theme.transitions.duration.standard,
  }),
  [theme.breakpoints.up('md')]: {
    width: 'initial',
    height: 'initial',
    borderBottom: 'initial',
  },
}))
