import {
  Box,
  Container,
  Link,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'

{
  /* TODO: enable in future */
}

// import FacebookLogo from '../assets/icons/facebook-logo.svg'
import { routeNamesPaths } from '@shared/constants'

import LinkedInLogo from '../assets/icons/linkedin-logo.svg'
import WhiteLogo from '../assets/logo-white.svg'
import { ContactUs } from './contact-us'
import { StyledTypography, StyledLogosBox, StyledLine } from './styled'

export function ContentMobile() {
  const theme = useTheme()
  const { t } = useTranslation('widgets')

  return (
    <Container maxWidth="lg">
      <StyledLogosBox mb={5}>
        <Link href="#" lineHeight={0} width="min-content" rel="noopener">
          <Box component="img" src={WhiteLogo} alt="Arbitas logo" width={139} />
        </Link>

        <Stack direction="row" spacing={2.5}>
          {/* TODO: enable in future */}
          {/* <Link href="#" lineHeight={0} rel="noopener">
            <Box component="img" src={FacebookLogo} alt="Facebook Logo" />
          </Link> */}

          <Link
            href="https://www.linkedin.com/company/arbitas/?originalSubdomain=at"
            target="_blank"
            lineHeight={0}
            rel="noopener"
          >
            <Box component="img" src={LinkedInLogo} alt="LinkedIn Logo" />
          </Link>
        </Stack>
      </StyledLogosBox>

      <ContactUs sx={{ mb: 5 }} />

      <Link href={routeNamesPaths.dataProcessing} mb={2.5}>
        <Typography
          variant="text16Medium"
          color={theme.palette.text.white}
          mb={2.75}
          component="p"
        >
          {t('footer.Personal data')}
        </Typography>
      </Link>

      {/* TODO: enable in future */}
      {/* <Box mt={5}>
        <Link href="#" lineHeight={0} underline="none" rel="noopener">
          <Typography variant="text16Semibold" color={theme.palette.text.white}>
            {t('footer.Send enquiry')}
          </Typography>
        </Link>
      </Box> */}
      <Stack mt={5} spacing={0} mb={4}>
        <Typography variant="footerText12" color={theme.palette.text.white}>
          {t('footer.For professional investors and intermediaries only')}
        </Typography>

        <Typography variant="footerText12" color={theme.palette.text.white}>
          {t('footer.This content is not suitable for retail audience')}
        </Typography>
      </Stack>

      <StyledLine mb={4} />

      <Stack gap={1.5}>
        {[...new Array(3)].map((_, index) => (
          <StyledTypography key={index} variant={'text14Regular'}>
            <Trans
              i18nKey={`widgets:footer.info text.${index}` as never}
              components={{ a: <a /> }}
            />
          </StyledTypography>
        ))}
      </Stack>

      <Box mt={5}>
        <Typography variant="text16Medium" color={theme.palette.text.disabled}>
          {t('footer.Licenced by Czech National Bank')}
        </Typography>
      </Box>
    </Container>
  )
}
