// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { Box, Stack, Typography, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { languages } from '@shared/i18n'

import CZFlagIcon from './assets/icons/cz.svg'
import ENFlagIcon from './assets/icons/en.svg'
import SKFlagIcon from './assets/icons/sk.svg'

import { StyledLinkButton } from './styled'

interface IProps {
  open: boolean
  handleClick: (event: React.MouseEvent<HTMLButtonElement>) => void
  isTransparentBackground: boolean
}

export default function LangButton({
  handleClick,
  open,
  isTransparentBackground,
}: IProps) {
  const theme = useTheme()

  const {
    t,
    i18n: { language, changeLanguage },
  } = useTranslation('entities')

  const [currentFullLangName, setCurrentFullLangName] = useState<string>()
  const [currentLangFlagIcon, setCurrentLangFlagIcon] = useState(ENFlagIcon)

  useEffect(() => {
    if (language === 'en') {
      setCurrentFullLangName(t('lang switcher.English'))

      setCurrentLangFlagIcon(ENFlagIcon)
    } else if (language === 'sk') {
      setCurrentFullLangName(t('lang switcher.Slovak'))

      setCurrentLangFlagIcon(SKFlagIcon)
    } else if (language === 'cz') {
      setCurrentFullLangName(t('lang switcher.Czech'))

      setCurrentLangFlagIcon(CZFlagIcon)
    }
  }, [language, t])

  useEffect(() => {
    if (!languages.find((item) => item === language)) {
      changeLanguage('en')

      setCurrentFullLangName(t('lang switcher.English'))

      setCurrentLangFlagIcon(ENFlagIcon)
    }
  }, [])

  return (
    <Stack
      justifyContent="center"
      id="basic-button"
      aria-controls={open ? 'basic-menu' : undefined}
      aria-haspopup="true"
      aria-expanded={open ? 'true' : undefined}
    >
      <StyledLinkButton
        onClick={handleClick}
        props={{ isTransparentBackground }}
        // endIcon={
        //   <KeyboardArrowDownIcon
        //     sx={{
        //       transition: theme.transitions.create(['transform'], {
        //         duration: theme.transitions.duration.standard,
        //       }),
        //       transform: open ? 'rotateX(180deg)' : 'rotate(0)',
        //     }}
        //   />
        // }
        disableRipple
      >
        <Box
          component="img"
          src={currentLangFlagIcon}
          alt={currentFullLangName + 'flag'}
          mr={1}
        />

        <Typography
          variant="text14Regular"
          color={
            isTransparentBackground
              ? theme.palette.text.white
              : theme.palette.text.primary
          }
        >
          {currentFullLangName}
        </Typography>
      </StyledLinkButton>
    </Stack>
  )
}
