import { Box, useMediaQuery, useTheme } from '@mui/material'

import {
  ContentMobile,
  BottomMobile,
  ContentDesktop,
  BottomDesktop,
} from './components'

export default function Footer() {
  const theme = useTheme()
  const mdAndUp = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <Box component="footer" sx={{ position: 'relative', zIndex: 10 }}>
      <Box
        sx={{
          pt: { xs: 6.5, md: 8 },
          pb: { xs: 2, md: 5.5 },
          backgroundColor: theme.palette.secondary.main,
        }}
      >
        {mdAndUp ? <ContentDesktop /> : <ContentMobile />}
      </Box>

      <Box
        sx={{
          pt: { xs: 2.5, md: 3 },
          pb: { xs: 3.25, md: 3 },
          backgroundColor: theme.palette.secondary.dark,
        }}
      >
        {mdAndUp ? <BottomDesktop /> : <BottomMobile />}
      </Box>
    </Box>
  )
}
