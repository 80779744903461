import { Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { Map } from '@shared/ui'

import { StyledLangButton, StyledLangTypography } from './styled'

export function LangList() {
  const { i18n } = useTranslation('features')
  const currentLanguage = i18n.language

  const languages = ['en', 'sk', 'cz']

  return (
    <Stack direction="row" ml={-1}>
      <Map
        collection={languages}
        iterate={(lang, index) => (
          <StyledLangButton
            onClick={() => i18n.changeLanguage(lang)}
            key={index}
          >
            <StyledLangTypography
              isCurrent={currentLanguage === lang}
              variant={
                currentLanguage === lang ? 'text18SemiBold' : 'text18Regular'
              }
            >
              {lang.toUpperCase()}
            </StyledLangTypography>
          </StyledLangButton>
        )}
      />
    </Stack>
  )
}
