import {
  SxProps,
  Container,
  Typography,
  Button,
  useMediaQuery,
  useTheme,
  Link,
} from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'

import { routeNamesPaths } from '@shared/constants'
import { Picture, TImage, TwoSideBlock } from '@shared/ui'

import BgPNG from '../assets/bg.png'
import BgWebP from '../assets/bg.webp'

import { StyledBox, pictureSx } from './styled'

interface IProps {
  sx?: SxProps
}

export function SubFundFintech({ sx }: IProps) {
  const { t } = useTranslation('widgets')
  const theme = useTheme()
  const mdAndUp = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <StyledBox sx={sx} component="section">
      <Container maxWidth={mdAndUp ? 'lg' : 'sm'}>
        <TwoSideBlock
          title={
            <Typography variant="h2" mb={{ xs: 6, md: 2.5 }}>
              <Trans
                ns="widgets"
                i18nKey="main-page.sub-fund fintech.title"
                components={{ br: <br /> }}
              />
            </Typography>
          }
          content={
            <>
              <Typography variant="text18Regular" mb={{ xs: 2.5, md: 5 }}>
                {t('main-page.sub-fund fintech.content')}
              </Typography>

              <Link
                href={routeNamesPaths.subFund}
                sx={{ width: '100%', maxWidth: 360, textDecoration: 'none' }}
              >
                <Button
                  fullWidth
                  sx={{ alignSelf: { sm: 'center', md: 'initial' } }}
                  variant="contained"
                >
                  {t('main-page.sub-fund fintech.button')}
                </Button>
              </Link>
            </>
          }
          sideContent={
            <Picture
              png={BgPNG as TImage<'png'>}
              webp={BgWebP as TImage<'webp'>}
              alt="Fintech"
              sx={pictureSx}
            />
          }
          reverse={mdAndUp ? true : false}
        />
      </Container>
    </StyledBox>
  )
}
