import { Box, Stack, SxProps, useMediaQuery, useTheme } from '@mui/material'
import { Fragment, ReactNode } from 'react'
import { IsEmpty } from '@shared/ui'

import { Wrapper, Divider } from './styled'

interface IProps {
  reverse?: boolean
  title?: ReactNode
  divider?: boolean
  content?: ReactNode
  sideContent?: ReactNode
  sx?: SxProps
  titleWithSubtitle?: boolean
}

export default function TwoSideBlock({
  reverse,
  content,
  sideContent,
  title,
  divider,
  sx,
  titleWithSubtitle,
}: IProps) {
  const theme = useTheme()
  const mdAndUp = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <Wrapper sx={sx} reverse={reverse}>
      <Stack className={'content'} flex={1} justifyContent="center">
        {(mdAndUp || titleWithSubtitle) && (
          <IsEmpty value={title} no={() => <Box>{title}</Box>} />
        )}

        <IsEmpty value={divider} no={() => <Divider />} />
        <Fragment>{content}</Fragment>
      </Stack>

      <Box className={'side-content'} flex={1}>
        {!mdAndUp && !titleWithSubtitle && (
          <IsEmpty value={title} no={() => <Box>{title}</Box>} />
        )}

        {sideContent}
      </Box>
    </Wrapper>
  )
}
