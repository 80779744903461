import { Helmet } from 'react-helmet'
import { ScrollRestoration, useLocation, useOutlet } from 'react-router-dom'
import { SwitchTransition, CSSTransition } from 'react-transition-group'

import { AppBar } from '@widgets/app-bar'
import { Footer } from '@widgets/footer'

import { routes } from '../router'
import { AnimatedBox } from './styled'

export function MainLayout() {
  const location = useLocation()
  const currentOutlet = useOutlet()

  const { nodeRef } =
    routes.find((route) => route.path === location.pathname) ?? {}

  window.document.body.style.overflowY = 'scroll'

  return (
    <>
      <Helmet
        encodeSpecialCharacters={true}
        defaultTitle="ARBITAS SICAV INVESTMENT FUND │ A bridge between technology and finance"
        titleTemplate="%s - ARBITAS SICAV INVESTMENT FUND │ A bridge between technology and finance"
      />

      <AppBar />

      <SwitchTransition>
        <CSSTransition
          key={location.pathname}
          nodeRef={nodeRef}
          timeout={300}
          classNames="page"
          unmountOnExit
        >
          <AnimatedBox ref={nodeRef} className="page">
            {currentOutlet}
          </AnimatedBox>
        </CSSTransition>
      </SwitchTransition>

      <Footer />
      <ScrollRestoration />
    </>
  )
}
