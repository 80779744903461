import { Box, styled } from '@mui/material'

export const TextBox = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  paddingBottom: '160px',
  marginLeft: '200px',
  maxWidth: 400,
  [theme.breakpoints.down('xl')]: {
    paddingLeft: theme.spacing(3),
  },

  [theme.breakpoints.down('lg')]: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    marginLeft: 0,
    paddingBottom: '110px',
  },
  [theme.breakpoints.down('sm')]: {
    maxWidth: '95%',
  },
}))
