import { Box } from '@mui/material'
import { styled } from '@mui/material'

import { appBarHeight } from '@shared/constants'
import { getCurrentBrowser } from '@shared/utils'

import BgImagePNG from '../assets/bg.webp'
import BgImageWebp from '../assets/bg.webp'

export const BgImageBox = styled(Box)(({ theme }) => ({
  height: `calc(100vh - ${appBarHeight.md})`,
  width: 'calc(100% + 200px)',
  background: `linear-gradient(195deg, rgba(0, 0, 0, 0.00) 10.83%, #000 89.21%), url(${
    getCurrentBrowser() === 'Safari' ? BgImagePNG : BgImageWebp
  }), lightgray 50% / cover no-repeat;`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  borderRadius: '0px 0px 20px 0px;',
  maxHeight: 820,
  position: 'absolute',
  right: 0,
  top: 0,

  [theme.breakpoints.down('lg')]: {
    width: '100vw',
    maxHeight: `calc(100vh - ${appBarHeight.md})`,
    backgroundSize: 'cover',
    backgroundPosition: 'left center',
    borderRadius: 0,
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
  },
}))
