import { Box, styled } from '@mui/material'

export const Bubble1 = styled(Box)(({ theme }) => ({
  width: 280,
  height: 280,
  borderRadius: '280px',
  opacity: 0.2,
  background: theme.palette.text.blue,
  filter: 'blur(66px)',
  position: 'absolute',
  top: -110,
  right: '50%',
  transform: 'translateX(50%)',
  [theme.breakpoints.up('md')]: {
    width: 421,
    height: 421,
    borderRadius: '421px',
    opacity: 0.5,
    filter: 'blur(100px)',
    top: -232,
    right: -11,
    transform: 'initial',
  },
}))
