import { useTheme } from '@mui/material'
import { memo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { StyledTextBox, StyledTypography } from './styled'

interface IProps {
  width: number
  index: number
}

export const MiddlePart = memo(function MiddlePart({ index, width }: IProps) {
  const theme = useTheme()

  useTranslation()

  return (
    <StyledTextBox
      sx={{
        width: '20%',
        left: '50%',
        transform: 'translateX(-50%)',
      }}
    >
      <StyledTypography
        variant="text16Medium"
        fontSize={width / 37.5}
        top="24%"
        opacity={index < 1}
      >
        <Trans
          ns="widgets"
          i18nKey="for startups.how to onboard my fintech.info graph.0"
          components={{ span: <span /> }}
        />
      </StyledTypography>

      <StyledTypography
        variant="subtitle25SemiBold"
        fontSize={width / 24}
        top="50%"
        sx={{
          textTransform: 'initial',
          color:
            index < 7
              ? theme.palette.secondary.dark
              : theme.palette.common.white,
          transform: 'translateY(-50%)',
        }}
      >
        <Trans
          ns="widgets"
          i18nKey="for startups.how to onboard my fintech.info graph.6"
          components={{ span: <span /> }}
        />
      </StyledTypography>

      <StyledTypography
        variant="text16Medium"
        fontSize={width / 37.5}
        top="81.5%"
        opacity={index < 4}
      >
        <Trans
          ns="widgets"
          i18nKey="for startups.how to onboard my fintech.info graph.3"
          components={{ span: <span /> }}
        />
      </StyledTypography>
    </StyledTextBox>
  )
})
