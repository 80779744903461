import { Link, styled } from '@mui/material'

export const StyledLink = styled(Link)(({ theme }) => ({
  padding: '10px 20px',
  display: 'block',
  border: '1px solid',
  borderColor: theme.palette.secondary.dark,
  borderRadius: 100,
  color: theme.palette.secondary.dark,
  textDecoration: 'none',
  transition: theme.transitions.create(['background-color', 'color'], {
    duration: theme.transitions.duration.standard,
  }),
  '&:hover': {
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.text.white,
  },
  [theme.breakpoints.up('md')]: {
    padding: '12px 24px',
  },
}))
