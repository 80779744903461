import { Grid, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { WhoWeAreItemList } from './styled'

export function WhoWeAreTabContent() {
  const { t } = useTranslation('widgets')

  return (
    <Grid container columnSpacing={7.5} rowSpacing={2.5}>
      <Grid item xs={12} md={6}>
        <Typography variant={'text18Regular'} component={'p'} mb={1}>
          {t('main-page.why arbitas sicav.Who we are desc 1')}
        </Typography>

        <Typography variant={'text18Regular'} component={'p'}>
          {t('main-page.why arbitas sicav.Who we are desc 2')}
        </Typography>
      </Grid>

      <Grid item xs={12} md={6}>
        <Stack rowGap={{ xs: 2, md: 4 }}>
          <WhoWeAreItemList
            borderColor={(theme) => theme.palette.secondary.main}
          >
            <Typography
              variant={'h2'}
              sx={(theme) => ({
                color: {
                  xs: theme.palette.tertiary.main,
                  md: theme.palette.primary.main,
                },
              })}
            >
              10 000 000 EUR
            </Typography>

            <Typography
              variant={'text14Regular'}
              color={(theme) => theme.palette.text.secondary}
            >
              {t('main-page.why arbitas sicav.Fund capital greater than')}
            </Typography>
          </WhoWeAreItemList>

          <WhoWeAreItemList borderColor={(theme) => theme.palette.primary.main}>
            <Typography
              variant={'h2'}
              fontSize={{ xs: 22, md: 28 }}
              fontWeight={{ xs: 700, md: 600 }}
              color={(theme) => theme.palette.tertiary.main}
            >
              {t('main-page.why arbitas sicav.up to p.a.', { percent: 12 })}
            </Typography>

            <Typography
              variant={'text14Regular'}
              color={(theme) => theme.palette.text.secondary}
            >
              {t(
                'main-page.why arbitas sicav.Above-standard return on investment shares'
              )}
            </Typography>
          </WhoWeAreItemList>

          <WhoWeAreItemList borderColor={(theme) => theme.palette.text.text}>
            <Typography
              variant={'h2'}
              fontSize={{ xs: 17, md: 28 }}
              fontWeight={{ xs: 700, md: 600 }}
              color={(theme) => theme.palette.tertiary.main}
            >
              {t('main-page.why arbitas sicav.Real assets with stable growth')}
            </Typography>

            <Typography
              variant={'text14Regular'}
              color={(theme) => theme.palette.text.secondary}
            >
              {t('main-page.why arbitas sicav.Investments and value')}
            </Typography>
          </WhoWeAreItemList>
        </Stack>
      </Grid>
    </Grid>
  )
}
