import { Box } from '@mui/material'

import { NavBar } from '@features/app-bar'

export default function MobileMenu() {
  return (
    <Box height="100vh">
      <NavBar isTransparentBackground={false} />
    </Box>
  )
}
