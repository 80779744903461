import { Box, styled } from '@mui/material'

export const Bubble3 = styled(Box)(({ theme }) => ({
  width: 421,
  height: 421,
  position: 'absolute',
  top: 94,
  right: 237,
  borderRadius: '421px',
  opacity: 0.7,
  background: theme.palette.text.blue,
  filter: 'blur(100px)',
  [theme.breakpoints.up('md')]: {
    top: -10,
    right: -160,
  },
}))
