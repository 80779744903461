import { Container, styled } from '@mui/material'

export const StyledContainer = styled(Container)(({ theme }) => ({
  maxHeight: '100vh',
  height: '100vh',
  position: 'relative',

  [theme.breakpoints.up('md')]: {
    height: 840,
  },
}))
