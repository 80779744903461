import { Box, styled } from '@mui/material'

export const StyledTextBox = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  position: 'relative',
  zIndex: 1,
  transition: theme.transitions.create(['padding', 'top'], {
    duration: theme.transitions.duration.standard,
  }),
}))
