import { Typography, Stack, useTheme, Link } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { routeNamesPaths } from '@shared/constants'

export function FollowUs() {
  const theme = useTheme()
  const { t } = useTranslation('widgets')

  return (
    <>
      <Typography
        variant="desktopFooterTitleUp"
        color={theme.palette.text.white}
        mb={1.5}
        component="h6"
      >
        {t('footer.Follow us')}
      </Typography>

      <Stack spacing={0.75} mb={5}>
        <Link
          href="https://www.linkedin.com/company/arbitas/?originalSubdomain=at"
          target="_blank"
          lineHeight={0}
          rel="noopener"
        >
          <Typography variant="text16Regular" color={theme.palette.text.white}>
            {t('footer.LinkedIn')}
          </Typography>
        </Link>

        {/* TODO: enable in future */}
        {/* <Link href="#" lineHeight={0} rel="noopener">
              <Typography
                variant="text16Regular"
                color={theme.palette.text.white}
              >
                {t('footer.Facebook')}
              </Typography>
            </Link> */}
      </Stack>

      <Link href={routeNamesPaths.dataProcessing}>
        <Typography
          variant="text16Medium"
          color={theme.palette.text.white}
          mb={2.75}
          component="p"
        >
          {t('footer.Personal data')}
        </Typography>
      </Link>

      <Typography
        variant="text14Regular"
        fontWeight={500}
        lineHeight="140%"
        component="p"
        color={theme.palette.text.licensed}
      >
        {t('footer.For professional investors and intermediaries only')} <br />
        {t('footer.This content is not suitable for retail audience')}
      </Typography>
    </>
  )
}
