import { styled, Typography } from '@mui/material'

export const StyledTitleTypography = styled(Typography)(({ theme }) => ({
  display: 'block',
  marginBottom: theme.spacing(1.25),
  transition: theme.transitions.create(['color'], {
    duration: theme.transitions.duration.standard,
  }),
  [theme.breakpoints.up(1200)]: {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: '2',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}))
