import { Box, Stack, Link } from '@mui/material'

// import FacebookLogo from '../assets/icons/facebook-logo.svg'
import LinkedInLogo from '../assets/icons/linkedin-logo.svg'

export function SocialLinksList() {
  return (
    <Stack spacing={2}>
      {/* <Link href="" target="_blank" lineHeight={0}>
        <Box component="img" src={FacebookLogo} alt="Facebook Logo" />
      </Link> */}

      <Link
        href="https://www.linkedin.com/company/arbitas/?originalSubdomain=at"
        target="_blank"
        lineHeight={0}
      >
        <Box component="img" src={LinkedInLogo} alt="LinkedIn Logo" />
      </Link>
    </Stack>
  )
}
