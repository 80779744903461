import { createRef } from 'react'
import { CustomRouteObject } from 'react-router-dom'
import { MainPage } from './ui'

const MainPageRouter: CustomRouteObject = {
  path: '/',
  element: <MainPage />,
  nodeRef: createRef(),
}

export default MainPageRouter
