import { memo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { StyledTextBox, StyledTypography } from './styled'

interface IProps {
  width: number
  index: number
}

export const RightPart = memo(function RightPart({ index, width }: IProps) {
  useTranslation()

  return (
    <StyledTextBox right={0}>
      <StyledTypography
        variant="text16Medium"
        fontSize={width / 37.5}
        top="38%"
        opacity={index < 2}
      >
        <Trans
          ns="widgets"
          i18nKey="for startups.how to onboard my fintech.info graph.1"
          components={{ span: <span /> }}
        />
      </StyledTypography>

      <StyledTypography
        variant="text16Medium"
        fontSize={width / 37.5}
        top="67%"
        opacity={index < 3}
      >
        <Trans
          ns="widgets"
          i18nKey="for startups.how to onboard my fintech.info graph.2"
          components={{ span: <span /> }}
        />
      </StyledTypography>
    </StyledTextBox>
  )
})
