import { styled } from '@mui/material'

export const StyledVideo = styled('video')(() => ({
  objectFit: 'cover',
  position: 'absolute',
  top: 0,
  left: 0,
  height: '100%',
  width: '100%',
  zIndex: -1,
}))
