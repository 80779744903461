import { PaletteOptions } from '@mui/material'

export const common = {
  primary: {
    main: '#009EE0',
    dark: '#006B99',
  },
  secondary: {
    main: '#0067DF',
    dark: '#003850',
  },
}

export const paletteOptions: PaletteOptions = {
  primary: {
    ...common.primary,
  },
  secondary: {
    ...common.secondary,
  },
  common: {
    white: '#FFFFFF',
  },
  error: {
    main: '#EE1C2E',
    light: '#FF436D',
    dark: '#D60033',
  },
  warning: {
    main: '#FF5F00',
    light: '#FF9900',
    dark: '#C74A00',
  },
  success: {
    main: '#059739',
    light: '#4CD96B',
    dark: '#087C21',
  },
  text: {
    primary: '#282828',
    secondary: '#909090',
    white: '#FFFFFF',
    text: '#99E0FF',
    disabled: '#F3F5F9',
    licensed: '#A4EDFD',
    blue: '#99E0FF',
  },
  background: {
    white: '#FFFFFF',
    blueLight: '#E7F7FF',
    violet: '#E6D7FE',
    blueDark: '#94C5FF',
  },
  mainBodyColors: {
    grayMain: '#909090',
    grayLight: '#ECECEC',
    purple: '#E8F1FF',
    violetLight: '#8394EB',
  },
  colorShadesKit: {
    csk50: '#E5F7FF',
    csk100: '#CCF0FF',
    csk200: '#99E0FF',
    csk300: '#66D1FF',
    csk400: '#33C2FF',
    csk500: '#009DE0',
    csk600: '#008FCC',
    csk700: '#006B99',
    csk800: '#004766',
    csk900: '#002433',
  },
  greyBlue: {
    main: '#F3F5F9',
  },
  blueGreen: {
    main: '#D4E7F3',
  },
  grey: {
    line: '#D4D8D9',
  },
  appBarTransparentBackground: '#0909091A',
  appBarButtonHover: '#0101010d',
  buttonHover: '#0052b3',
  radialLine: '#009ee029',
  tertiary: {
    main: '#0f498c',
  },
}
