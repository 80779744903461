import { Box, styled } from '@mui/material'

export const BallBubble2 = styled(Box)(({ theme }) => ({
  width: 226,
  height: 128,
  position: 'absolute',
  top: '50%',
  right: 0,
  transform: 'translateY(-20%)',
  borderRadius: '100%',
  backgroundColor: theme.palette.background.violet,
  filter: 'blur(50px)',
}))
