import { Stack, styled } from '@mui/material'

export const GeneralDescMobile = styled(Stack)(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    display: 'none',
  },
  borderRadius: 8,
  background: theme.palette.mainBodyColors.purple,
  color: theme.palette.colorShadesKit.csk800,
  fontSize: 14,
  fontWeight: 400,
  lineHeight: 1.4,
  textAlign: 'center',
  padding: 16,
  marginTop: 24,
  marginLeft: 'auto',
  marginRight: 'auto',
  minHeight: 92,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  [theme.breakpoints.up('sm')]: {
    width: 326,
  },
}))
