import {
  Banner,
  LatestInsights,
  SubFundFintech,
  WhyArbitasSicav,
} from '@widgets/main-page'

export function MainPage() {
  return (
    <>
      <Banner sx={{ mb: { xs: 5.5, md: 18.75 } }} />
      <WhyArbitasSicav sx={{ mb: { xs: 5, md: 18.75 } }} />
      <SubFundFintech sx={{ mb: { xs: 5.5, md: 12.25 } }} />
      <LatestInsights sx={{ mb: { xs: 5.5, md: 21.25 } }} />
    </>
  )
}
