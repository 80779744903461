import { Box, styled } from '@mui/material'

export const IndicatorArea = styled(Box)(({ theme }) => ({
  position: 'absolute',
  width: '100%',
  height: 0,
  background: theme.palette.mainBodyColors.grayLight,
  bottom: 1,

  [theme.breakpoints.up('md')]: {
    height: 2,
  },
}))
