import { Box, styled } from '@mui/material'

export const StyledOverlayBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  bottom: 0,
  right: 0,
  left: 0,
  height: 264,
  borderRadius: theme.spacing(2.5),
  background: '#000',
  backgroundColor: 'transparent',
  transition: theme.transitions.create(
    ['backdrop-filter', 'background-color', 'height'],
    {
      duration: theme.transitions.duration.standard,
    }
  ),
}))
