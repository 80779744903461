import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

export const WhoWeAreItemList = styled(Box)(({ theme }) => ({
  borderLeftWidth: '8px',
  borderLeftStyle: 'solid',
  paddingLeft: 12,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  height: 72,
  rowGap: theme.spacing(1),

  [theme.breakpoints.up('md')]: {
    border: 0,
    padding: 0,
    justifyContent: 'flex-start',
    height: 'auto !important',
    rowGap: 0,
  },
}))
