import { Typography } from '@mui/material'

export function CompanyAddress() {
  return (
    <>
      <Typography variant="text16Regular" color="inherit">
        ARBITAS SICAV, a.s.
      </Typography>

      <Typography variant="text16Regular" color="inherit">
        se sídlem Hvězdova 171 6/2b, Nusle, 140 00 Praha 4,
      </Typography>

      <Typography variant="text16Regular" color="inherit">
        IČO 195 84 776,
      </Typography>

      <Typography variant="text16Regular" color="inherit">
        zapsaný v obchodním rejstříku vedeném Městský soudem v Praze, oddíl B,
        vložka 28300
      </Typography>
    </>
  )
}
