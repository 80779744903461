export const splideOptions = {
  // type: 'slide',
  rewind: true,
  gap: 60,
  perPage: 3,
  pagination: false,
  autoplay: true,
  perMove: 1,
  interval: 15 * 1000,
  height: 532,
  breakpoints: {
    1268: {
      perPage: 2,
    },
    1200: {
      autoplay: false,
      height: undefined,
      arrows: false,
      pagination: true,
      perPage: 3,
      gap: 40,
    },
    1010: {
      perPage: 2,
    },
    670: {
      perMove: 1,
      perPage: 1,
    },
  },
}
