import { Box, SxProps, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import CZFlagIcon from './assets/icons/cz.svg'
import ENFlagIcon from './assets/icons/en.svg'
import SKFlagIcon from './assets/icons/sk.svg'

import { StyledMenuItem } from './styled'

interface IProps {
  lang: string
  handleClose: () => void
  sx?: SxProps
  isTransparentBackground: boolean
}

export default function LangListItem({
  handleClose,
  lang,
  sx,
  isTransparentBackground,
}: IProps) {
  const {
    t,
    i18n: { language, changeLanguage },
  } = useTranslation('entities')

  const handleMenuItemClick = (lang: string) => {
    changeLanguage(lang)

    handleClose()
  }

  const [fullLangName, setFullLangName] = useState<string>()
  const [langFlagIcon, setLangFlagIcon] = useState(ENFlagIcon)

  useEffect(() => {
    if (lang === 'en') {
      setFullLangName(t('lang switcher.English'))

      setLangFlagIcon(ENFlagIcon)
    } else if (lang === 'sk') {
      setFullLangName(t('lang switcher.Slovak'))

      setLangFlagIcon(SKFlagIcon)
    } else if (lang === 'cz') {
      setFullLangName(t('lang switcher.Czech'))

      setLangFlagIcon(CZFlagIcon)
    }
  }, [lang, t])

  return (
    <StyledMenuItem
      onClick={() => handleMenuItemClick(lang)}
      props={{
        isBlue: language === lang,
        isTransparentBackground: isTransparentBackground,
      }}
      sx={{ width: '100%', ...sx }}
    >
      <Box
        component="img"
        src={langFlagIcon}
        alt={fullLangName + 'flag'}
        mr={1}
      />

      <Typography variant="text14Regular" lineHeight="14px">
        {fullLangName}
      </Typography>
    </StyledMenuItem>
  )
}
