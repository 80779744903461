import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

export const LevelBlockText = styled(Box)(() => ({
  position: 'absolute',
  top: '0',
  left: '0',
  bottom: '0',
  right: '0',
  display: 'flex',
  columnGap: 10,
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
}))
