import { Box, useMediaQuery, useTheme } from '@mui/material'

import { LangList, MenuList, SocialLinksList } from './components'
import { StyledLangSocialLinksContainer } from './styled'

interface INavBar {
  isTransparentBackground: boolean
}

export default function NavBar({ isTransparentBackground }: INavBar) {
  const theme = useTheme()
  const mdAndUp = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <Box component="nav">
      <MenuList isTransparentBackground={isTransparentBackground} />

      {!mdAndUp && (
        <StyledLangSocialLinksContainer>
          <LangList />
          <SocialLinksList />
        </StyledLangSocialLinksContainer>
      )}
    </Box>
  )
}
