export const downloadPresentationPath = '/for-investors/download-presentation'

export const presentationPaths = {
  subFund: 'sub-fund-fintech',
  arbipoint: 'arbipoint',
} as const

type TPresentationKeys = keyof typeof presentationPaths

export type TPresentationPaths = (typeof presentationPaths)[TPresentationKeys]

export const routeNamesPaths = {
  mainPage: '/',
  forInvestors: '/for-investors',
  subFund: '/sub-fund',
  arbipointMarketplace: '/for-investors/arbipoint-marketplace',
  arbipayOnlineBank: '/for-investors/arbipay-online-bank',
  arbisoft: '/for-investors/arbisoft',
  arbiex: '/for-investors/arbiex',
  downloadPresentation: `${downloadPresentationPath}/:presentation`,
  forStartups: '/for-startups',
  aboutUs: '/about-us',
  contacts: '/contacts',
  dataProcessing: '/contacts/data-processing',
  uiKit: '/ui-kit/typography',
} as const

type TKeys = keyof typeof routeNamesPaths

export type TRouteNamesPaths = (typeof routeNamesPaths)[TKeys]
