import { Box, styled } from '@mui/material'

export const StyledBox = styled(Box)(({ theme }) => ({
  padding: '32px 16px',
  borderRadius: theme.spacing(2.5),
  backgroundColor: theme.palette.mainBodyColors.purple,
  [theme.breakpoints.up('sm')]: {
    padding: '38px 24px',
  },
}))
