import { styled } from '@mui/material'

interface IProps {
  last: boolean
}

export const StyledKeyTdBox = styled('div', {
  shouldForwardProp: (prop) => prop !== 'last',
})<IProps>(({ theme, last }) => ({
  height: '100%',
  padding: '28px 40px',
  backgroundColor: theme.palette.greyBlue.main,
  borderRight: '1px solid',
  ...(!last && { borderBottom: '1px solid' }),
  borderColor: theme.palette.grey.line,
}))
