import { Grid, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ReactComponent as EffiencyA } from './icons/efficiency-active.svg'
import { ReactComponent as ReturnA } from './icons/return-active.svg'
import { ReactComponent as ReturnD } from './icons/return-disabled.svg'
import { ReactComponent as EffiencyD } from './icons/return-disabled.svg'
import { ReactComponent as RiskA } from './icons/risk-active.svg'
import { ReactComponent as RiskD } from './icons/risk-disabled.svg'
import { ReactComponent as FirstH } from './levels-svg/first-header.svg'
import { ReactComponent as First } from './levels-svg/first.svg'
import { ReactComponent as SecondH } from './levels-svg/second-header.svg'
import { ReactComponent as Second } from './levels-svg/second.svg'
import { ReactComponent as Sibling } from './levels-svg/sibling.svg'
import { ReactComponent as Third } from './levels-svg/third.svg'
import {
  GeneralDescMobile,
  LevelBlock,
  LevelBlockText,
  LevelDesc,
  LevelTitle,
} from './styled'

export function OurStrategyTabContent() {
  const { t } = useTranslation('widgets')
  const [active, setActive] = useState(1)
  const theme = useTheme()
  const lgAndUp = useMediaQuery(theme.breakpoints.up('lg'))

  useEffect(() => {
    const interval = setInterval(() => {
      setActive((prevState) => {
        if (prevState >= 3) {
          return 1
        }

        return prevState + 1
      })
    }, 3000)

    return () => clearInterval(interval)
  }, [])

  return (
    <Grid container columnSpacing={7.5} rowSpacing={2.5}>
      <Grid item xs={12} md={6}>
        <Typography variant={'text18Regular'} component={'p'}>
          {t('main-page.why arbitas sicav.Our Strategy desc')}
        </Typography>
      </Grid>

      <Grid item xs={12} md={6}>
        <Stack>
          <Stack direction={'row'} alignItems={'flex-end'} mb={'11px'}>
            <LevelBlock className={`third ${active > 2 ? '' : 'disabled'}`}>
              <Third />

              <LevelBlockText className={'text'}>
                {active > 2 ? <RiskA /> : <RiskD />}

                <LevelTitle>
                  {t('main-page.why arbitas sicav.Risk analysis')}
                </LevelTitle>
              </LevelBlockText>
            </LevelBlock>

            {lgAndUp && (
              <LevelBlock className={active > 2 ? '' : 'disabled'}>
                <Sibling />

                <LevelBlockText>
                  <LevelDesc>
                    {t('main-page.why arbitas sicav.Risk analyze desc')}
                  </LevelDesc>
                </LevelBlockText>
              </LevelBlock>
            )}
          </Stack>

          <Stack direction={'row'} alignItems={'flex-end'} mb={'11px'}>
            <LevelBlock className={`second ${active > 1 ? '' : 'disabled'}`}>
              <SecondH />
              <Second />

              <LevelBlockText className={'text'}>
                {active > 1 ? <ReturnA /> : <ReturnD />}

                <LevelTitle>
                  {t('main-page.why arbitas sicav.Return')}
                </LevelTitle>
              </LevelBlockText>
            </LevelBlock>

            {lgAndUp && (
              <LevelBlock className={active > 1 ? '' : 'disabled'}>
                <Sibling />

                <LevelBlockText>
                  <LevelDesc>
                    {t('main-page.why arbitas sicav.Return desc')}
                  </LevelDesc>
                </LevelBlockText>
              </LevelBlock>
            )}
          </Stack>

          <Stack direction={'row'} alignItems={'flex-end'}>
            <LevelBlock className={active > 0 ? '' : 'disabled'}>
              <FirstH />
              <First />

              <LevelBlockText>
                {active > 0 ? <EffiencyA /> : <EffiencyD />}

                <LevelTitle>
                  {t('main-page.why arbitas sicav.Efficiency')}
                </LevelTitle>
              </LevelBlockText>
            </LevelBlock>

            {lgAndUp && (
              <LevelBlock className={active > 0 ? '' : 'disabled'}>
                <Sibling />

                <LevelBlockText>
                  <LevelDesc>
                    {t('main-page.why arbitas sicav.Efficiency desc')}
                  </LevelDesc>
                </LevelBlockText>
              </LevelBlock>
            )}
          </Stack>

          <GeneralDescMobile>
            {active === 1 && t('main-page.why arbitas sicav.Risk analyze desc')}
            {active === 2 && t('main-page.why arbitas sicav.Return desc')}
            {active > 2 && t('main-page.why arbitas sicav.Efficiency desc')}
          </GeneralDescMobile>
        </Stack>
      </Grid>
    </Grid>
  )
}
