import { Box, styled } from '@mui/material'

import { getCurrentBrowser } from '@shared/utils'

import BlockchainJPG from '../../../assets/blockchain.jpg'
import BlockchainWebP from '../../../assets/blockchain.webp'

export const StyledBgBox = styled(Box)(({ theme }) => ({
  width: '100%',
  height: 510,
  marginBottom: theme.spacing(5),
  borderRadius: theme.spacing(2.5),
  backgroundImage: `url(${
    getCurrentBrowser() === 'Safari' ? BlockchainJPG : BlockchainWebP
  })`,
  backgroundSize: 'cover',
  [theme.breakpoints.up('sm')]: {
    height: 234,
    marginBottom: 0,
    backgroundPositionY: '30%',
  },
  [theme.breakpoints.up('lg')]: {
    height: '100%',
  },
}))
