import { Grid, styled } from '@mui/material'

export const StyledGridItem = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(-4),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  position: 'relative',
  zIndex: 1,
  [theme.breakpoints.up('md')]: {
    marginTop: 0,
  },
}))
