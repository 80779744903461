import { SxProps, Container, Typography } from '@mui/material'
import { Splide, SplideSlide } from '@splidejs/react-splide'
// eslint-disable-next-line import/no-internal-modules
import '@splidejs/react-splide/css'
import { useTranslation } from 'react-i18next'

import { InsightCard } from '@entities/main-page'

import { Map } from '@shared/ui'

import { splideOptions, useLatestInsights } from '../model'
import { StyledBox } from './styled'

interface IProps {
  sx?: SxProps
}

export function LatestInsights({ sx }: IProps) {
  const { t } = useTranslation('widgets')
  const latestInsights = useLatestInsights()

  return (
    <Container maxWidth="lg" component="section" sx={sx}>
      <StyledBox>
        <Typography variant="h2" mb={{ xs: 3.5, md: 5 }}>
          {t('main-page.latest insights.title')}
        </Typography>

        <Splide options={splideOptions}>
          <Map
            collection={latestInsights}
            iterate={(insight, index) => (
              <SplideSlide key={index}>
                <InsightCard
                  date={insight.date}
                  image={insight.image}
                  insight={insight.insight}
                  title={insight.title}
                />
              </SplideSlide>
            )}
          />
        </Splide>
      </StyledBox>
    </Container>
  )
}
