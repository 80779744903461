import { Stack, styled } from '@mui/material'

export const StyledStack = styled(Stack)(({ theme }) => ({
  borderStyle: 'solid',
  borderWidth: '1px 0 1px',
  borderColor: `transparent transparent ${theme.palette.mainBodyColors.grayLight}`,
  [theme.breakpoints.up('md')]: {
    borderColor: 'transparent',
  },
}))
