import { Box, styled } from '@mui/material'

export const StyledMainBallBox = styled(Box)(({ theme }) => ({
  width: 415,
  height: 415,
  display: 'none',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-48%, -45%)',
  borderRadius: '415px',
  background:
    'radial-gradient(101.64% 101.64% at 100% 63%, rgba(37, 65, 209, 0.00) 68.21%, rgba(22, 50, 197, 0.19) 100%), #FAFAFA',
  [theme.breakpoints.up('md')]: {
    display: 'block',
  },
}))
