import { Stack, styled } from '@mui/material'

export const StyledTableStack = styled(Stack)<{ component: React.ElementType }>(
  ({ theme }) => ({
    border: '1px solid',
    borderColor: theme.palette.grey.line,
    borderRadius: theme.spacing(2.5),
    overflow: 'hidden',
    borderCollapse: 'collapse',
  })
)
