import { Box, Divider, useMediaQuery, useTheme } from '@mui/material'
import { languages } from '@shared/i18n'

import LangListItem from './lang-list-item'

import { StyledMenuLocal } from './styled'

interface IProps {
  open: boolean
  anchorEl: HTMLElement | null
  handleClose: () => void
  isTransparentBackground: boolean
}

export default function LangList({
  anchorEl,
  handleClose,
  open,
  isTransparentBackground,
}: IProps) {
  const theme = useTheme()
  const mdAndUp = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <StyledMenuLocal
      id="demo-simple-select"
      props={{ isTransparentBackground }}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
      disableScrollLock={true}
    >
      {languages.map((lang: string, index: number) => (
        <Box key={index}>
          <LangListItem
            lang={lang}
            handleClose={handleClose}
            isTransparentBackground={isTransparentBackground}
            sx={{ mb: { md: index !== languages.length - 1 ? 1.5 : 0 } }}
          />

          {!mdAndUp && index !== languages.length - 1 && (
            <Divider
              sx={{
                m: '0 auto !important',
                width: 58,
                borderColor: '#F2FAFD',
                borderRadius: 2,
              }}
            />
          )}
        </Box>
      ))}
    </StyledMenuLocal>
  )
}
