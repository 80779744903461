import { Box, styled } from '@mui/material'

import { getCurrentBrowser } from '@shared/utils'

import SideBgJPG from '../../assets/side-bg.jpg'
import SideBgWebP from '../../assets/side-bg.webp'

export const StyledSideBgBox = styled(Box)(({ theme }) => ({
  height: 370,
  marginBottom: theme.spacing(4),
  borderRadius: theme.spacing(2.5),
  backgroundImage: `url(${
    getCurrentBrowser() === 'Safari' ? SideBgJPG : SideBgWebP
  })`,
  backgroundSize: 'cover',
  [theme.breakpoints.up('md')]: {
    height: '100%',
    marginBottom: 0,
    backgroundPositionX: '21%',
  },
}))
