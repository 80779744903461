import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

export const LevelBlock = styled(Box)(({ theme }) => ({
  position: 'relative',
  height: 81,
  margin: '0 auto',

  '& .figure path, & .figure-sibling path, & .figure-header path, & .MuiTypography-root':
    {
      transition: theme.transitions.create(['fill'], {
        duration: theme.transitions.duration.standard,
      }),
    },

  '&.disabled': {
    '.figure path, .figure-sibling path': {
      fill: theme.palette.text.disabled,
    },
    '.figure-header path': {
      fill: theme.palette.mainBodyColors.grayLight,
    },
    '.MuiTypography-root': {
      color: theme.palette.mainBodyColors.grayMain,
    },
  },

  '& .figure-header': {
    position: 'absolute',
    left: '50%',
    top: -11,
    transform: 'translateX(-50%)',
  },

  [theme.breakpoints.up('lg')]: {
    '&.third': {
      paddingLeft: 31,
      '& .text': {
        paddingLeft: 31,
      },
    },

    '&.second': {
      paddingLeft: 16,
      '& .text': {
        paddingLeft: 16,
      },

      '& .figure-header': {
        transform: 'translateX(calc(-50% + 16px / 2))',
      },
    },
  },
}))
