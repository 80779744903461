import { Button, styled } from '@mui/material'

export const StyledLinkButton = styled(Button)<{
  props: {
    isTransparentBackground: boolean
  }
}>(({ theme, props }) => ({
  padding: '16px 0 16px 16px' + '!important',
  perspective: '10000px',
  color: props.isTransparentBackground
    ? theme.palette.common.white
    : theme.palette.text.primary,
  ':hover': {
    backgroundColor: 'initial',
    color: props.isTransparentBackground
      ? theme.palette.common.white
      : theme.palette.primary.main,
  },
  '& .MuiButton-endIcon': {
    marginLeft: 0,
    '& svg': {
      fontSize: 24,
    },
  },
}))
