import { Box, styled } from '@mui/material'

export const StyledOverlayBox = styled(Box)(() => ({
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  background: 'rgba(0, 0, 0, 0.32)',
  zIndex: -1,
}))
