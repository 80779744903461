import { Menu, styled } from '@mui/material'

export const StyledMenuLocal = styled(Menu)<{
  props: {
    isTransparentBackground: boolean
  }
}>(({ theme, props }) => ({
  marginTop: theme.spacing(1),
  borderRadius: 3,
  '& .MuiPaper-root': {
    width: 'auto',
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.12)',
    borderRadius: theme.spacing(1.5),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(1.25),
      paddingBottom: theme.spacing(1.25),
    },
  },
  '& .MuiList-root': {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    gap: 2,
  },
  [theme.breakpoints.up('md')]: {
    marginTop: theme.spacing(2),
    '& .MuiPaper-root': {
      paddingRight: '8px',
      paddingLeft: '8px',
      marginLeft: '-8px',
      background: props.isTransparentBackground
        ? theme.palette.appBarTransparentBackground
        : theme.palette.common.white,
      boxShadow: props.isTransparentBackground
        ? 'none'
        : '0px 2px 12px rgba(0, 0, 0, 0.12)',
    },
  },
}))
