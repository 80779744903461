import {
  CssBaseline,
  LinkProps,
  ThemeProvider as MuiThemeProvider,
  createTheme,
} from '@mui/material'
import React, { ReactNode } from 'react'
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom'

import { defaultTheme } from './default-theme'
import { paletteOptions } from './palette-options'
import { typographyOptions } from './typography-options'

interface IProps {
  children: ReactNode
}

// eslint-disable-next-line react/display-name
const LinkBehavior = React.forwardRef<
  HTMLAnchorElement,
  Omit<RouterLinkProps, 'to'> & {
    href: RouterLinkProps['to']
  }
>((props, ref) => {
  const { href, ...other } = props
  // Map href (MUI) -> to (react-router)

  return <RouterLink ref={ref} to={href} {...other} />
})

const important = ' !important'

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1248,
      xl: 1536,
    },
  },
  palette: paletteOptions,
  typography: typographyOptions,
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        a: {
          textDecorationColor: 'transparent',
          textDecorationThickness: 1,
          transition: defaultTheme.transitions.create(
            ['text-decoration-color'],
            {
              duration: defaultTheme.transitions.duration.standard,
            }
          ),
          ':hover': {
            textDecorationColor: paletteOptions.text?.white,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          display: 'flex',
          padding: '16px',
          borderRadius: 8,
          boxShadow: 'none',
          fontSize: typographyOptions.buttonText.fontSize,
          fontWeight: typographyOptions.buttonText.fontWeight,
          lineHeight: typographyOptions.buttonText.lineHeight,
          color: paletteOptions.common?.white,
          textTransform: 'none',
          '&.Mui-disabled': {
            background: paletteOptions.mainBodyColors?.grayLight,
            color: paletteOptions.text?.disabled,
          },
        },
        contained: {
          backgroundColor: paletteOptions.secondary?.main,
          '&:hover': {
            backgroundColor: paletteOptions.buttonHover,
            boxShadow: 'none',
          },
        },
        outlined: {
          border: `1px solid ${paletteOptions.secondary?.main}`,
          color: paletteOptions.secondary?.main,
        },
      },
    },
    MuiButtonBase: {
      defaultProps: { LinkComponent: LinkBehavior },
    },
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      } as LinkProps,
      styleOverrides: {
        root: {
          width: 'max-content',
          textDecorationColor: 'transparent',
          textDecorationThickness: 1,
          transition: defaultTheme.transitions.create(
            ['text-decoration-color'],
            {
              duration: defaultTheme.transitions.duration.standard,
            }
          ),
          ':hover': {
            textDecorationColor: paletteOptions.text?.white,
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
          alignItems: 'end',
          '&:not(.MuiInputBase-multiline)': {
            input: {
              paddingBottom: '2px',
            },
          },
          '&.MuiInputBase-multiline': {
            height: 'initial',
            paddingBottom: '4px',
            '& textarea': {
              fontSize: typographyOptions.subtitle14UpMedium.fontSize,
              fontWeight: typographyOptions.subtitle14UpMedium.fontWeight,
              lineHeight: typographyOptions.subtitle14UpMedium.lineHeight,
              textTransform: typographyOptions.subtitle14UpMedium.textTransform,
              '::placeholder': {
                color: paletteOptions.text?.secondary,
                opacity: 1,
                fontWeight: typographyOptions.subtitle14UpMedium.fontWeight,
                textTransform:
                  typographyOptions.subtitle14UpMedium.textTransform,
              },
            },
          },
          '&.Mui-disabled': {
            color: paletteOptions.text?.disabled + important,
            fontSize: typographyOptions.subtitle14UpMedium.fontSize,
            fontWeight: typographyOptions.subtitle14UpMedium.fontWeight,
            lineHeight: typographyOptions.subtitle14UpMedium.lineHeight,
            textTransform: typographyOptions.subtitle14UpMedium.textTransform,
          },
          '&.Mui-disabled:before': {
            borderBottomStyle: 'solid' + important,
            borderBottomColor: paletteOptions.text?.disabled,
            color: paletteOptions.text?.disabled + important,
          },
          input: {
            fontSize: typographyOptions.subtitle14UpMedium.fontSize,
            fontWeight: typographyOptions.subtitle14UpMedium.fontWeight,
            lineHeight: typographyOptions.subtitle14UpMedium.lineHeight,
            textTransform: typographyOptions.subtitle14UpMedium.textTransform,
            '::placeholder': {
              color: paletteOptions.text?.secondary,
              opacity: 1,
              fontWeight: typographyOptions.subtitle14UpMedium.fontWeight,
              lineHeight: typographyOptions.subtitle14UpMedium.lineHeight,
              textTransform: typographyOptions.subtitle14UpMedium.textTransform,
            },
            '&.Mui-disabled': {
              textFillColor: paletteOptions.text?.disabled,
            },
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          '&:before': {
            borderBottom: `1px solid ${paletteOptions.mainBodyColors?.grayMain}`,
          },
          '&:hover:not(.Mui-disabled, .Mui-error):before': {
            borderBottom: `1px solid ${paletteOptions.mainBodyColors?.grayMain}`,
          },
          '&:after': {
            borderBottom: `1px solid ${paletteOptions.mainBodyColors?.violetLight}`,
          },
        },
        input: {
          '&.Mui-disabled': {
            textFillColor: paletteOptions.text?.disabled,
          },
          '&:-webkit-autofill, &:-webkit-autofill:hover , &:-webkit-autofill:focus':
            {
              WebkitTextFillColor: 'currentColor',
              WebkitBoxShadow: '0 0 0px 1000px #fff inset',
              transition: 'background-color 5000s ease-in-out 0s',
            },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {},
      },
    },
    MuiContainer: {
      styleOverrides: {
        disableGutters: {
          paddingLeft: 0,
          paddingRight: 0,
        },
        root: {
          paddingLeft: '24px',
          paddingRight: '24px',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          '& .colored--blue': {
            color: paletteOptions.primary?.main,
          },
          '& .colored--secondary-main': {
            color: paletteOptions.secondary?.main,
          },
        },
      },
    },
  },
})

export default function ThemeProvider({ children }: IProps) {
  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </MuiThemeProvider>
  )
}
