import lodash from 'lodash'
import { Fragment, ReactNode } from 'react'

type NoFunction<T> = (value: T) => ReactNode

interface IProps<T> {
  value?: T
  yes?: ReactNode
  no?: NoFunction<T>
}

export default function IsEmpty<T>({ no, yes, value }: IProps<T>) {
  if (lodash(value).isEmpty()) {
    return <Fragment>{yes}</Fragment>
  }

  return <Fragment>{no && no(value as T)}</Fragment>
}
