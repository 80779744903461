import { Drawer, styled } from '@mui/material'

import { appBarHeight } from '@shared/constants'

export const StyledDrawer = styled(Drawer)(({ theme }) => ({
  width: '100%',
  flexShrink: 0,
  [`& .MuiDrawer-paper`]: {
    width: '100%',
    boxSizing: 'border-box',
    marginTop: appBarHeight.xs,
    [theme.breakpoints.up('lg')]: {
      marginTop: appBarHeight.md,
    },
  },
}))
