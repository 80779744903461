import { Container, Grid, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'

export function BottomDesktop() {
  const { t } = useTranslation('widgets')
  const theme = useTheme()

  return (
    <Container maxWidth="lg">
      <Grid container>
        <Grid item xs={8} alignSelf="center">
          <Typography
            variant="text14Regular"
            color={theme.palette.text.disabled}
            fontWeight={500}
          >
            {t(
              'footer.Copyright Ⓒ 2023 ARBITAS SICAV, Investment fund with legal personality'
            )}
          </Typography>
        </Grid>

        <Grid
          item
          xs={4}
          display={'flex'}
          justifyContent={'flex-end'}
          alignItems={'center'}
        >
          <Typography
            variant="text14Regular"
            color={theme.palette.text.disabled}
            fontWeight={500}
            ml={'auto'}
          >
            {t('footer.Licenced by Czech National Bank')}
          </Typography>
        </Grid>
      </Grid>
    </Container>
  )
}
