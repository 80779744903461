import { Divider, styled } from '@mui/material'

export const StyledDivider = styled(Divider)(({ theme }) => ({
  width: '66%',
  marginBottom: theme.spacing(6),
  borderColor: theme.palette.primary.main,
  [theme.breakpoints.up('md')]: {
    borderColor: theme.palette.secondary.dark,
    marginBottom: theme.spacing(7),
  },
}))
