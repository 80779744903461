import { SxProps, Stack } from '@mui/material'
import React, { useState } from 'react'

import { LangButton, LangList } from '@entities/lang-switcher'

interface PropsInterface {
  sx?: SxProps
  isTransparentBackground: boolean
}

export default function LanguageSwitcher({
  sx,
  isTransparentBackground,
}: PropsInterface) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Stack justifyContent="center" height={{ xs: 64, md: '100%' }} sx={sx}>
      <LangButton
        open={open}
        isTransparentBackground={isTransparentBackground}
        handleClick={handleClick}
      />

      <LangList
        open={open}
        isTransparentBackground={isTransparentBackground}
        anchorEl={anchorEl}
        handleClose={handleClose}
      />
    </Stack>
  )
}
