import { Container, SxProps, Typography } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import { TabInfo } from './components'

interface IProps {
  sx?: SxProps
}

export function WhyArbitasSicav({ sx }: IProps) {
  useTranslation()

  return (
    <Container
      maxWidth={'lg'}
      component="section"
      sx={{ overflow: 'hidden', ...sx }}
    >
      <Typography
        variant={'h2'}
        mb={{ xs: '28px', md: '100px' }}
        sx={{ '& span': { textTransform: 'lowercase' } }}
      >
        <Trans
          ns="widgets"
          i18nKey="main-page.why arbitas sicav.title"
          components={{ span: <span /> }}
        />
      </Typography>

      {/*TODO important - errors in consoles!!!!!*/}
      <TabInfo />
    </Container>
  )
}
