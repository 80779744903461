import { styled } from '@mui/material'

interface IProps {
  reverse?: boolean
}

const Wrapper = styled('div')<IProps>(({ theme, reverse = false }) => ({
  display: 'flex',
  flexDirection: reverse ? 'column' : 'column-reverse',
  gap: 16,
  justifyContent: 'center',
  [theme.breakpoints.up('md')]: {
    flexDirection: reverse ? 'row' : 'row-reverse',
    gap: 60,
  },
}))

export default Wrapper
