import { Button, styled } from '@mui/material'

export const StyledButton = styled(Button)(({ theme }) => ({
  width: '100%',
  minWidth: 'initial',
  height: '100%',
  padding: 0,
  borderRadius: 0,
  justifyContent: 'flex-start',
  color: theme.palette.text.primary,
  textTransform: 'initial',
  ':hover': {
    backgroundColor: 'initial',
  },
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1.25),
    ':hover': {
      backgroundColor: 'transparent',
    },
  },
  '&.Mui-disabled': {
    backgroundColor: 'transparent',
    opacity: 0.5,
  },
}))
