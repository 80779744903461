import {
  createBrowserRouter,
  RouterProvider as ReactRouterProvider,
} from 'react-router-dom'

import { ArbiexPageRoute } from '@pages/arbiex'
import { ArbipayOnlineBankPageRouter } from '@pages/arbipay-online-bank'
import { ArbipointMarketplacePageRouter } from '@pages/arbipoint-marketplace'
import { ArbisoftPageRoute } from '@pages/arbisoft'
import { ContactsPageRouter } from '@pages/contacts-page'
import { DataProcessingPageRouter } from '@pages/data-processing'
import { DownloadPresentationPageRouter } from '@pages/download-presentation'
import { ForInvestorsPageRouter } from '@pages/for-investors'
import { ForStartupPageRoute } from '@pages/for-startups'
import { MainPageRouter } from '@pages/main'
import { SubFundPageRouter } from '@pages/sub-fund'

import { ErrorLayout, MainLayout } from './layouts'

// eslint-disable-next-line react-refresh/only-export-components
export const routes = [
  MainPageRouter,
  ForInvestorsPageRouter,
  SubFundPageRouter,
  ContactsPageRouter,
  ArbipointMarketplacePageRouter,
  ArbipayOnlineBankPageRouter,
  ArbisoftPageRoute,
  ForStartupPageRoute,
  ArbiexPageRoute,
  DownloadPresentationPageRouter,
  DataProcessingPageRouter,
]

const router = createBrowserRouter([
  {
    path: '/',
    element: <MainLayout />,
    errorElement: <ErrorLayout />,
    children: routes,
  },
  {
    path: '*',
    element: <ErrorLayout />,
  },
])

export default function RouterProvider() {
  return <ReactRouterProvider router={router} />
}
