import { Box, styled } from '@mui/material'

export const StyledBubble1 = styled(Box)<{ index?: number }>(
  ({ theme, index }) => ({
    background: index
      ? theme.palette.text.blue
      : theme.palette.background.violet,
    filter: 'blur(46px)',
    opacity: index ? 0.9 : 1,
    width: 128,
    height: 74,
    position: 'absolute',
    right: -38,
    top: index ? 20 : 26,
    [theme.breakpoints.up('md')]: {
      width: 224,
      height: 126,
      right: index ? -64 : 0,
      top: index ? 45 : 4,
      filter: 'blur(80px)',
    },
  })
)
