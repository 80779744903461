import { MenuItem, styled } from '@mui/material'

interface IProps {
  props: {
    isBlue: boolean
    isTransparentBackground: boolean
  }
}

export const StyledMenuItem = styled(MenuItem)<IProps>(({ theme, props }) => ({
  width: 'min-content',
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  marginLeft: 'auto',
  marginRight: 'auto',
  borderRadius: theme.spacing(1),
  color: props.isBlue
    ? theme.palette.secondary.main
    : theme.palette.text.primary,
  ':hover': {
    backgroundColor: theme.palette.mainBodyColors.grayLight,
  },
  transition: theme.transitions.create(['background-color'], {
    duration: theme.transitions.duration.standard,
  }),
  [theme.breakpoints.up('md')]: {
    height: 40,
    alignItems: 'center',
    marginLeft: 0,
    color: !props.isTransparentBackground
      ? props.isBlue
        ? theme.palette.secondary.main
        : theme.palette.text.primary
      : theme.palette.text.white,
    ':hover': {
      backgroundColor: props.isTransparentBackground
        ? theme.palette.appBarButtonHover
        : theme.palette.mainBodyColors.grayLight,
    },
  },
}))
