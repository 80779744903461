import { useMediaQuery, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { useMobileMenu } from '@entities/app-bar'

import { routeNamesPaths } from '@shared/constants'
import { Map } from '@shared/ui'

import { IMenuItem } from '../model'
import { MenuItem } from './menu-item'
import { StyledLi, StyledStack } from './styled'

interface IProps {
  isTransparentBackground: boolean
}

export function MenuList({ isTransparentBackground }: IProps) {
  const { t } = useTranslation('features')
  const theme = useTheme()
  const and1000Up = useMediaQuery(theme.breakpoints.up(1000))
  const mdAndUp = useMediaQuery(theme.breakpoints.up('md'))
  const setShowMobileMenu = useMobileMenu((state) => state.setShowMobileMenu)

  const pages: IMenuItem[] = [
    {
      name: t('app bar.Sub-fund fintech'),
      navigationLink: routeNamesPaths.subFund,
    },
    {
      name: t('app bar.For investors'),
      navigationLink: routeNamesPaths.forInvestors,
    },
    {
      name: t('app bar.For Startups'),
      navigationLink: routeNamesPaths.forStartups,
    },
    // {
    //   name: t('app bar.About us'),
    //   navigationLink: routeNamesPaths.aboutUs,
    // },
    {
      name: t('app bar.Contacts'),
      navigationLink: routeNamesPaths.contacts,
    },
  ]

  const handleClick = () => {
    setShowMobileMenu(false)
  }

  return (
    <StyledStack
      direction={mdAndUp ? 'row' : 'column'}
      spacing={{ xs: 0, md: and1000Up ? 1 : 0, lg: 3.5 }}
      component="ul"
    >
      <Map
        collection={pages}
        iterate={(page, index) => (
          <StyledLi key={index} onClick={handleClick}>
            <MenuItem
              isTransparentBackground={isTransparentBackground}
              name={page.name}
              navigationLink={page.navigationLink}
              disabled={page.disabled}
            />
          </StyledLi>
        )}
      />
    </StyledStack>
  )
}
