import { Box, styled } from '@mui/material'
import { getCurrentBrowser } from '@shared/utils'

interface IProps {
  png: string
  webp: string
  index?: number
}

export const BackgroundBox = styled(Box)<IProps>(
  ({ theme, png, webp, index }) => ({
    display: 'block',
    paddingBottom: !index ? '58%' : '80%',
    backgroundImage: `url(${getCurrentBrowser() === 'Firefox' ? png : webp})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    position: 'relative',
    zIndex: 2,
    [theme.breakpoints.up('sm')]: {
      paddingBottom: index ? 390 : 282,
    },
    [theme.breakpoints.up('md')]: {
      height: '100%',
      backgroundPosition: 'center',
      paddingBottom: 'initial',
      backgroundSize: 'contain',
    },
  })
)
