import { styled, Typography } from '@mui/material'

export const StyledInsightTypography = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  display: 'block',
  [theme.breakpoints.up(1200)]: {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: '3',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    transition: theme.transitions.create(['color'], {
      duration: theme.transitions.duration.standard,
    }),
  },
}))
