import { Box, styled } from '@mui/material'

export const Bubble4 = styled(Box)(({ theme }) => ({
  width: 280,
  height: 280,
  borderRadius: '280px',
  opacity: 0.2,
  background: theme.palette.text.blue,
  filter: 'blur(66px)',
  position: 'absolute',
  bottom: -184,
  left: -108,
  [theme.breakpoints.up('md')]: {
    width: 421,
    height: 421,
    borderRadius: '421px',
    opacity: 0.5,
    filter: 'blur(100px)',
    bottom: -184,
    left: -108,
  },
}))
