import { Box, styled } from '@mui/material'

export const StyledBox = styled(Box)(({ theme }) => ({
  maxWidth: 450,
  position: 'absolute',
  bottom: 8,
  [theme.breakpoints.up('md')]: {
    position: 'initial',
  },
}))
