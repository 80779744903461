import { Typography, styled } from '@mui/material'

export const StyledStepTypography = styled(Typography)<{
  component: React.ElementType
}>(({ theme }) => ({
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
  borderBottom: '1px solid',
  borderColor: theme.palette.blueGreen.main,
  lineHeight: '140%',
  [theme.breakpoints.up('md')]: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    lineHeight: '30px',
  },
  [theme.breakpoints.up('lg')]: {
    paddingTop: theme.spacing(3.25),
    paddingBottom: theme.spacing(3.25),
  },
}))
