import { Box, styled } from '@mui/material'

interface IProps {
  isTransparentBackground: boolean
}

export const StyledLogoBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isTransparentBackground',
})<IProps>(({ theme, isTransparentBackground }) => ({
  width: 110,
  '& path': {
    transition: theme.transitions.create('fill', {
      duration: theme.transitions.duration.standard,
    }),
    fill: !isTransparentBackground
      ? theme.palette.primary.main
      : theme.palette.common.white,
  },
  [theme.breakpoints.up('md')]: {
    width: 120,
  },
  [theme.breakpoints.up(1000)]: {
    width: 134,
  },
}))
