import { Box, styled } from '@mui/material'

interface IProps {
  index?: number
}

export const StyledBox = styled(Box)<IProps>(({ theme, index }) => ({
  padding: '20px 20px 8px',
  borderRadius: theme.spacing(2),
  paddingBottom: index ? theme.spacing(8) : theme.spacing(18),
  position: 'relative',
  overflow: 'hidden',
  backgroundColor: theme.palette.greyBlue.main,
  [theme.breakpoints.up('md')]: {
    padding: index ? '32px 16px' : '50px 16px',
    height: 400,
    marginBottom: theme.spacing(2.5),
    borderRadius: theme.spacing(3.5),
  },
}))
