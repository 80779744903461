import { TabContext, TabPanel } from '@mui/lab'
import { Box, Tabs } from '@mui/material'
import { SyntheticEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { OurStrategyTabContent } from './our-strategy-tab-content.tsx'
import { IndicatorArea, StyledTab } from './styled'
import { WhoWeAreTabContent } from './who-we-are-tab-content'

export function TabInfo() {
  const { t } = useTranslation('widgets')
  const [value, setValue] = useState('1')

  const handleChange = (_event: SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }

  return (
    <Box sx={{ minHeight: { md: 504, lg: 390 } }}>
      <TabContext value={value}>
        <Tabs
          value={value}
          variant="fullWidth"
          indicatorColor="primary"
          onChange={handleChange}
          sx={{
            minHeight: { xs: 28, md: 48 },
            '& .MuiTabs-indicator': {
              borderRadius: '100px',
              height: { xs: 2, md: 4 },
            },
          }}
        >
          <StyledTab
            label={t('main-page.why arbitas sicav.Who we are')}
            value={'1'}
          />

          <StyledTab
            label={t('main-page.why arbitas sicav.Our Strategy')}
            value={'2'}
          />

          <IndicatorArea />
        </Tabs>

        <Box sx={{ mt: { xs: 2, md: 8 }, mb: 4 }}>
          <TabPanel value={'1'} sx={{ p: 0 }}>
            <WhoWeAreTabContent />
          </TabPanel>

          <TabPanel value={'2'} sx={{ p: 0 }}>
            <OurStrategyTabContent />
          </TabPanel>
        </Box>
      </TabContext>
    </Box>
  )
}
