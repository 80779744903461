import { styled } from '@mui/material'

export const AnimatedBox = styled('main')(({ theme }) => ({
  '&.page-enter': {
    opacity: 0,
  },

  '&.page-enter-active': {
    transition: theme.transitions.create('opacity', {
      duration: theme.transitions.duration.standard,
    }),
    opacity: 1,
  },

  '&.page-exit': {
    opacity: 1,
  },

  '&.page-exit-active': {
    transition: theme.transitions.create('opacity', {
      duration: theme.transitions.duration.standard,
    }),
    opacity: 0,
  },
}))
