import { Box, styled } from '@mui/material'

export const Bubble2 = styled(Box)(({ theme }) => ({
  width: 431,
  height: 431,
  position: 'absolute',
  bottom: -225,
  right: -150,
  borderRadius: '431px',
  opacity: 0.7,
  background: theme.palette.background.violet,
  filter: 'blur(100px)',
  [theme.breakpoints.up('md')]: {
    bottom: 0,
    right: -90,
  },
}))
