import { Box, styled } from '@mui/material'

import { appBarHeight } from '@shared/constants'

import { getCurrentBrowser } from '@shared/utils'

import BgImagePNG from '../assets/bg.png'
import BgImageWebp from '../assets/bg.webp'

export const BgImageBox = styled(Box)(({ theme }) => ({
  height: `calc(100vh - ${appBarHeight.xs}px)`,
  width: 'calc(100% + 200px)',
  background: `linear-gradient(73deg, #000 9.52%, rgba(0, 0, 0, 0.00) 57.46%), url(${
    getCurrentBrowser() === 'Safari' ? BgImagePNG : BgImageWebp
  }), lightgray 0px -519.033px / 100% 213.415% no-repeat;`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  maxHeight: 820,
  borderRadius: '0px 0px 0px 20px',

  [theme.breakpoints.down('lg')]: {
    width: '100vw',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    borderRadius: 0,
    maxHeight: `calc(100vh - ${appBarHeight.md})`,
    height: '100%',
  },
}))
