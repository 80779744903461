import { Typography, styled } from '@mui/material'

interface IProps {
  isCurrent: boolean
}

export const StyledLangTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isCurrent',
})<IProps>(({ theme, isCurrent }) => ({
  color: isCurrent ? theme.palette.primary.main : theme.palette.text.primary,
}))
