import { Box, styled } from '@mui/material'

export const StyledBubble2 = styled(Box)<{ index?: number }>(
  ({ theme, index }) => ({
    display: 'none',
    background: theme.palette.text.blue,
    filter: 'blur(80px)',
    opacity: 0.9,
    width: 224,
    height: 126,
    position: 'absolute',
    left: 0,
    top: index ? 170 : 131,
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  })
)
