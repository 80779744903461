import { TypographyOptions } from '@mui/material/styles/createTypography'
import * as React from 'react'

import { defaultTheme } from './default-theme'

interface ExtendedTypographyOptions extends TypographyOptions {
  text14Regular: React.CSSProperties
  text15Regular: React.CSSProperties
  text16Regular: React.CSSProperties
  text16Semibold: React.CSSProperties
  text16Medium: React.CSSProperties
  text16MediumUp: React.CSSProperties
  text18Regular: React.CSSProperties
  text18Italic: React.CSSProperties
  text18Medium: React.CSSProperties
  text18SemiBold: React.CSSProperties
  text18Bold: React.CSSProperties
  text20Regular: React.CSSProperties
  text20Italic: React.CSSProperties
  subtitle42Up: React.CSSProperties
  subtitle28Up: React.CSSProperties
  subtitle28UpMobile: React.CSSProperties
  subtitle25SemiBold: React.CSSProperties
  subtitle22Up: React.CSSProperties
  subtitle22: React.CSSProperties
  subtitle20Medium: React.CSSProperties
  subtitle20: React.CSSProperties
  subtitle18Up: React.CSSProperties
  subtitle14UpMedium: React.CSSProperties
  subtitle14Up: React.CSSProperties
  subtitle40: React.CSSProperties
  mobileSubtitle14Up: React.CSSProperties
  buttonText: React.CSSProperties
  mobileFooterTitleUp: React.CSSProperties
  desktopFooterTitleUp: React.CSSProperties
  footerText12: React.CSSProperties
  body4: React.CSSProperties
  numeric1: React.CSSProperties
}

export const typographyOptions: ExtendedTypographyOptions = {
  fontFamily: [
    'Poppins',
    'sans-serif',
    '-apple-system',
    'BlinkMacSystemFont',
    'Segoe UI',
    'Roboto',
    'Helvetica Neue',
    'Arial',
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol',
  ].join(','),
  h1: {
    fontSize: 28,
    fontWeight: 600,
    lineHeight: '150%',
    textTransform: 'uppercase',
    [defaultTheme.breakpoints.up('md')]: {
      fontSize: 60,
      fontWeight: 500,
      lineHeight: '120%',
      textTransform: 'initial',
    },
  },
  h2: {
    fontSize: 28,
    fontWeight: 600,
    lineHeight: '130%',
    textTransform: 'uppercase',
    [defaultTheme.breakpoints.up('md')]: {
      fontSize: 40,
      fontWeight: 600,
      lineHeight: '130%',
    },
  },
  h3: {
    fontSize: 25,
    fontWeight: 500,
    lineHeight: '130%',
    textTransform: 'uppercase',
    [defaultTheme.breakpoints.up('md')]: {
      fontSize: 25,
      fontWeight: 700,
      lineHeight: '120%',
      textTransform: 'initial',
    },
  },
  body4: {
    fontSize: 16,
    lineHeight: '28px',
  },
  text14Regular: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '14px',
  },
  text15Regular: {
    fontSize: 15,
    fontWeight: 400,
    lineHeight: 1.4,
  },
  text16Regular: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '24px',
  },
  text16Semibold: {
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '150%',
  },
  text16Medium: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '130%',
  },
  text16MediumUp: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '130%',
    textTransform: 'uppercase',
  },
  text18Regular: {
    fontSize: 18,
    fontWeight: 400,
    lineHeight: '156%',
    [defaultTheme.breakpoints.up('md')]: {
      fontSize: 18,
      fontWeight: 400,
      lineHeight: '166%',
    },
  },
  text18Italic: {
    fontSize: 18,
    fontStyle: 'italic',
    fontWeight: 500,
    lineHeight: '130%',
  },
  text18Medium: {
    fontSize: 18,
    fontWeight: 500,
    lineHeight: '130%',
  },
  text18SemiBold: {
    fontSize: 18,
    fontWeight: 600,
    lineHeight: '133%',
  },
  text18Bold: {
    fontSize: 18,
    fontWeight: 700,
    lineHeight: '167%',
  },
  text20Regular: {
    fontSize: 20,
    fontWeight: 400,
    lineHeight: 'normal',
    [defaultTheme.breakpoints.up('md')]: {
      fontSize: 20,
      fontWeight: 400,
      lineHeight: '140%',
    },
  },
  text20Italic: {
    fontSize: 20,
    fontWeight: 500,
    fontStyle: 'italic',
    lineHeight: '130%',
  },
  subtitle42Up: {
    fontSize: 32,
    fontWeight: 700,
    lineHeight: '130%',
    textTransform: 'uppercase',
    letterSpacing: '0.64px',
    [defaultTheme.breakpoints.up('md')]: {
      fontSize: 42,
      letterSpacing: '1px',
    },
  },
  subtitle28Up: {
    fontSize: 28,
    fontWeight: 500,
    lineHeight: 'normal',
    textTransform: 'uppercase',
  },
  subtitle28UpMobile: {
    fontSize: 25,
    fontWeight: 600,
    lineHeight: '160%',
    textTransform: 'uppercase',
  },
  subtitle25SemiBold: {
    fontSize: 25,
    fontWeight: 600,
    lineHeight: '120%',
  },
  subtitle22Up: {
    fontSize: 22,
    fontWeight: 700,
    lineHeight: '130%',
    textTransform: 'uppercase',
    [defaultTheme.breakpoints.up('md')]: {
      fontSize: 22,
      fontWeight: 600,
      lineHeight: '130%',
    },
  },
  subtitle20: {
    fontSize: 20,
    fontWeight: 600,
    lineHeight: 'normal',
    [defaultTheme.breakpoints.up('md')]: {
      fontSize: 20,
      fontWeight: 500,
      lineHeight: '140%',
    },
  },
  subtitle20Medium: {
    fontSize: 20,
    fontWeight: 500,
    lineHeight: '120%',
  },
  subtitle18Up: {
    fontSize: 18,
    fontWeight: 400,
    lineHeight: 'normal',
    textTransform: 'uppercase',
  },
  subtitle14UpMedium: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 'normal',
    textTransform: 'uppercase',
    [defaultTheme.breakpoints.up('md')]: {
      lineHeight: '140%',
    },
  },
  subtitle14Up: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '140%',
    textTransform: 'uppercase',
  },
  subtitle40: {
    fontSize: 40,
    fontWeight: 500,
    lineHeight: '150%',
  },
  buttonText: {
    fontSize: 17,
    fontWeight: 600,
    lineHeight: '140%',
  },
  mobileSubtitle14Up: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '140%',
    textTransform: 'uppercase',
  },
  mobileFooterTitleUp: {
    fontSize: 20,
    fontWeight: 600,
    lineHeight: '130%',
    textTransform: 'uppercase',
    letterSpacing: 0.4,
  },
  desktopFooterTitleUp: {
    fontSize: 18,
    fontWeight: 700,
    lineHeight: '24px',
    textTransform: 'uppercase',
    letterSpacing: 0.36,
  },
  footerText12: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '140%',
  },
  subtitle22: {
    fontSize: 22,
    fontWeight: 600,
    lineHeight: '130%',
  },
  numeric1: {
    fontSize: 56,
    fontWeight: 400,
    lineHeight: 'normal',

    [defaultTheme.breakpoints.up('lg')]: {
      fontSize: 96,
      fontWeight: 300,
      lineHeight: 1,
    },
  },
}
