import { Stack, styled } from '@mui/material'

interface IProps {
  odd: boolean
  last: boolean
}

export const StyledMobileItemStack = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'last' && prop !== 'odd',
})<IProps>(({ theme, last, odd }) => ({
  minHeight: 106,
  padding: theme.spacing(2),
  backgroundColor: odd ? theme.palette.greyBlue.main : undefined,
  ...(!last && { borderBottom: '1px solid' }),
  borderColor: theme.palette.grey.line,
}))
