import { Container, Stack, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'

export function BottomMobile() {
  const { t } = useTranslation('widgets')
  const theme = useTheme()

  return (
    <Container maxWidth="lg">
      <Stack>
        <Typography variant="footerText12" color={theme.palette.text.white}>
          {t('footer.Copyright Ⓒ 2023 ARBITAS SICAV')}
        </Typography>

        <Typography variant="footerText12" color={theme.palette.text.white}>
          {t('footer.Investment fund with legal personality')}
        </Typography>
      </Stack>
    </Container>
  )
}
