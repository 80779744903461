import { format } from 'date-fns'
import { Trans, useTranslation } from 'react-i18next'

import First from '../../assets/1.webp'
import Second from '../../assets/2.webp'
import Third from '../../assets/3.webp'
import Fourth from '../../assets/4.webp'
import Fifth from '../../assets/5.webp'
// import ATMImg from '../../assets/atm.webp'
// import CityImg from '../../assets/city.webp'
// import ConferenceImg from '../../assets/conference.webp'
// import PhoneImg from '../../assets/phone.webp'

interface IInsight {
  title: string
  image: string
  insight: React.ReactElement
  alt: string
  date: string
}

export const useLatestInsights = () => {
  const { t } = useTranslation('widgets')

  const latestInsights: IInsight[] = [
    {
      title: t('main-page.latest insights.First.title'),
      image: First,
      insight: (
        <Trans i18nKey="widgets:main-page.latest insights.First.insight" />
      ),
      alt: 'License',
      date: format(new Date('14 August 2023'), 'dd MMMM yyyy'),
    },
    {
      title: t('main-page.latest insights.Third.title'),
      image: Third,
      insight: (
        <Trans i18nKey="widgets:main-page.latest insights.Third.insight" />
      ),
      alt: 'Celebrating new partnership with SKPAY',
      date: format(new Date('18 August 2023'), 'dd MMMM yyyy'),
    },
    {
      title: t('main-page.latest insights.Second.title'),
      image: Second,
      insight: (
        <Trans i18nKey="widgets:main-page.latest insights.Second.insight" />
      ),
      alt: 'Slovakia map',
      date: format(new Date('31 August 2023'), 'dd MMMM yyyy'),
    },
    {
      title: t('main-page.latest insights.4.title'),
      image: Fourth,
      insight: <Trans i18nKey="widgets:main-page.latest insights.4.insight" />,
      alt: 'Celebrating new partnership with DEUS',
      date: format(new Date('26 April 2024'), 'dd MMMM yyyy'),
    },
    {
      title: t('main-page.latest insights.5.title'),
      image: Fifth,
      insight: <Trans i18nKey="widgets:main-page.latest insights.5.insight" />,
      alt: 'Fintech Fund license in Slovakia!',
      date: format(new Date('24 July 2024'), 'dd MMMM yyyy'),
    },
    // {
    //   title: t('main-page.latest insights.Arbipoint Kiosks.title'),
    //   image: ATMImg,
    //   insight: (
    //     <Trans i18nKey="widgets:main-page.latest insights.Arbipoint Kiosks.insight" />
    //   ),
    //   alt: 'Phone',
    //   date: '26 July 2023',
    // },
  ]

  return latestInsights.reverse()
}
