import { Stack, styled } from '@mui/material'

export const StyledStack = styled(Stack)<{ component: string }>(
  ({ theme }) => ({
    listStyle: 'none',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    padding: 0,
    margin: 0,
    [theme.breakpoints.up('md')]: {
      display: '-webkit-box',
      alignItems: 'center',
    },
  })
)
