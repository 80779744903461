import { Box, styled } from '@mui/material'

export const MobileGradient = styled(Box)(({ theme }) => ({
  height: '100%',
  position: 'relative',
  zIndex: 1,
  background:
    'linear-gradient(25deg, #FFF 15.29%, rgba(255, 255, 255, 0.00) 53.2%)',
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}))
