import { styled, AppBar } from '@mui/material'

export const StyledAppBar = styled(AppBar)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  width: '100%',
  position: 'fixed',
  backgroundColor: 'transparent',
  justifyContent: 'center',
  boxShadow: 'none',
}))
