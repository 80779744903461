import { Typography, styled } from '@mui/material'

export const StyledTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'opacity',
})<{ opacity?: boolean }>(({ theme, opacity }) => ({
  width: '100%',
  display: 'block',
  position: 'absolute',
  whiteSpace: 'pre-wrap',
  textAlign: 'center',
  textTransform: 'uppercase',
  opacity: opacity ? 0.3 : 1,
  transition: theme.transitions.create(['opacity', 'color'], {
    duration: theme.transitions.duration.standard,
  }),
  '& .bold': {
    fontWeight: 600,
    lineHeight: '24px',
  },
}))
