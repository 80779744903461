import { Box, styled } from '@mui/material'

export const StyledImageBox = styled(Box)<{ image: string }>(
  ({ theme, image }) => ({
    height: 300,
    marginBottom: theme.spacing(2.5),
    borderRadius: theme.spacing(2),
    backgroundImage: `url(${image})`,
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    transition: theme.transitions.create(['height', 'width'], {
      duration: theme.transitions.duration.standard,
    }),
    [theme.breakpoints.up(1200)]: {
      marginBottom: theme.spacing(0),
      borderRadius: 20,
      position: 'absolute',
      right: 0,
      left: 0,
      height: 264,
    },
  })
)
