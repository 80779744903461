import { Box, styled } from '@mui/material'

export const StyledBox = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(7),
  backgroundColor: theme.palette.greyBlue.main,
  [theme.breakpoints.up('md')]: {
    paddingTop: theme.spacing(15),
    paddingBottom: theme.spacing(15),
  },
}))
