import { Tab } from '@mui/material'
import { styled } from '@mui/material/styles'

export const StyledTab = styled(Tab)(({ theme }) => ({
  fontSize: 14,
  paddingTop: 0,
  paddingBottom: 2,
  textTransform: 'unset',
  minHeight: 28,

  [theme.breakpoints.up('md')]: {
    fontSize: 25,
    paddingTop: 0,
    paddingBottom: 3,
    minHeight: 48,
  },

  '&.Mui-selected': {
    fontWeight: 700,
    color: theme.palette.text.primary,
  },

  '& .MuiTouchRipple-child': {
    backgroundColor: theme.palette.primary.main,
  },
}))
