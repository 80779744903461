import { Box, styled } from '@mui/material'

export const GradientBox = styled(Box)<{ index?: number }>(
  ({ theme, index }) => ({
    display: index ? 'block' : 'none',
    height: 136,
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 2,
    background:
      'linear-gradient(180deg, rgba(243, 245, 249, 0.00) -9.93%, #F3F5F9 19.76%)',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  })
)
