import { Box, styled } from '@mui/material'

export const Bubble4 = styled(Box)(({ theme }) => ({
  width: 520,
  height: 520,
  position: 'absolute',
  bottom: -12,
  left: 196,
  borderRadius: '520px',
  opacity: 0.6,
  background: theme.palette.background.blueDark,
  filter: 'blur(100px)',
  [theme.breakpoints.up('md')]: {
    bottom: 15,
    left: -129,
  },
}))
