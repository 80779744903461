import { Box, styled } from '@mui/material'

export const Bubble2 = styled(Box)(({ theme }) => ({
  width: 346,
  height: 346,
  borderRadius: '346px',
  opacity: 0.2,
  background: theme.palette.background.blueDark,
  filter: 'blur(66px)',
  position: 'absolute',
  bottom: -76,
  right: -176,
  [theme.breakpoints.up('md')]: {
    width: 520,
    height: 520,
    borderRadius: '520px',
    opacity: 0.5,
    filter: 'blur(100px)',
    background: theme.palette.background.violet,
    top: 172,
    right: -231,
    bottom: 'initial',
  },
}))
