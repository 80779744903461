import { Box, styled } from '@mui/material'

export const Bubble3 = styled(Box)(({ theme }) => ({
  width: 431,
  height: 431,
  display: 'none',
  borderRadius: '431px',
  opacity: 0.5,
  background: theme.palette.background.violet,
  filter: 'blur(100px)',
  position: 'absolute',
  bottom: -97,
  left: 231,
  [theme.breakpoints.up('md')]: {
    display: 'block',
  },
}))
