import {
  SxProps,
  Container,
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material'
import { useTranslation } from 'react-i18next'

import { LinksList } from '@features/error'

import { ReactComponent as IllustrationIcon } from '../assets/illustration.svg'
import { StyledGridItem } from './styled'

interface IProps {
  sx?: SxProps
}

export function ErrorPage({ sx }: IProps) {
  const { t } = useTranslation('pages')
  const theme = useTheme()
  const mdAndUp = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <Container
      maxWidth="lg"
      sx={{ pt: { xs: 1 }, pb: { xs: 4 }, py: { md: 10 }, ...sx }}
    >
      <Grid container spacing={{ xs: 2.5, md: 6.5 }}>
        <Grid
          item
          xs={12}
          md={4.3}
          sx={{ '& svg': { maxHeight: { xs: 264, sm: 460, md: 560 } } }}
        >
          <IllustrationIcon width="100%" height="100%" />
        </Grid>

        <StyledGridItem item xs={12} md={7.7}>
          <Typography
            variant="subtitle42Up"
            color={theme.palette.secondary.dark}
            textAlign="center"
            component="h1"
            mb={2}
          >
            {t('error.title')}
          </Typography>

          <Typography
            variant={mdAndUp ? 'text18Medium' : 'text16Medium'}
            textAlign="center"
            mb={{ xs: 4, md: 6, lg: 9 }}
            component="p"
          >
            {t('error.subtitle')}
          </Typography>

          <LinksList />
        </StyledGridItem>
      </Grid>
    </Container>
  )
}
