import { Box, styled } from '@mui/material'

export const BallBubble1 = styled(Box)(({ theme }) => ({
  width: 220,
  height: 126,
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  borderRadius: '100%',
  backgroundColor: theme.palette.text.blue,
  filter: 'blur(50px)',
}))
