import { Box, Container, Link, Grid, Stack } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'

import WhiteFundLogo from '../assets/logo-fund-white.svg'
import { ContactUs } from './contact-us'
import { FollowUs } from './follow-us'
import { StyledTypography, StyledLine } from './styled'

export function ContentDesktop() {
  useTranslation()

  return (
    <Container maxWidth="lg">
      <Grid container>
        <Grid item xs={4}>
          <Link href="#" lineHeight={0} width="min-content" rel="noopener">
            <Box
              component="img"
              src={WhiteFundLogo}
              alt="Arbitas fund logo"
              width={145}
            />
          </Link>
        </Grid>

        <Grid item xs={4}>
          <ContactUs />
        </Grid>

        <Grid item xs={4}>
          <FollowUs />
        </Grid>
      </Grid>

      <StyledLine sx={{ my: 6.5 }} />

      <Stack gap={1.5}>
        {[...new Array(3)].map((_, index) => (
          <StyledTypography key={index} variant={'text14Regular'}>
            <Trans
              i18nKey={`widgets:footer.info text.${index}` as never}
              components={{ a: <a /> }}
            />
          </StyledTypography>
        ))}
      </Stack>
    </Container>
  )
}
