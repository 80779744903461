import { Box, styled } from '@mui/material'

export const Bubble1 = styled(Box)(({ theme }) => ({
  width: 431,
  height: 431,
  position: 'absolute',
  top: -180,
  left: -214,
  borderRadius: '431px',
  opacity: 0.7,
  background: theme.palette.background.violet,
  filter: 'blur(100px)',
  [theme.breakpoints.up('md')]: {
    top: -54,
    left: -90,
  },
}))
