import { Box, styled } from '@mui/material'

export const StyledBox = styled(Box)(({ theme }) => ({
  padding: '46px 20px',
  display: 'flex',
  gap: theme.spacing(3.75),
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.greyBlue.main,
  borderRadius: theme.spacing(2),
}))
