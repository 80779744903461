import { Box, styled } from '@mui/material'

export const StyledMainBox = styled(Box)(({ theme }) => ({
  position: 'relative',
  overflow: 'hidden',
  [theme.breakpoints.up(1200)]: {
    width: 360,
    height: 452,
    '&:hover': {
      '.image': {
        height: '100%',
      },
      '.title, .content': {
        color: theme.palette.text.white,
        display: 'block',
      },
      '.text-box': {
        paddingRight: theme.spacing(2.5),
        paddingLeft: theme.spacing(2.5),
      },
      '.margin-box': {
        height: 32,
      },
      '.content': {
        WebkitLineClamp: 'initial',
      },
      '.date': {
        display: 'none',
      },
      '.overlay': {
        backgroundColor: 'rgba(53, 53, 53, 0.66)',
        backdropFilter: 'blur(8px)',
        height: '100%',
      },
    },
  },
}))
