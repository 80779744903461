import { styled, Toolbar } from '@mui/material'

import { appBarHeight } from '@shared/constants'

interface IProps {
  isTransparentBackground: boolean
}

export const StyledToolbar = styled(Toolbar, {
  shouldForwardProp: (prop) => prop !== 'isTransparentBackground',
})<IProps>(({ theme, isTransparentBackground }) => ({
  backgroundColor: theme.palette.background.white,
  justifyContent: 'space-between',
  height: appBarHeight.xs,
  minHeight: appBarHeight.xs + 'px' + '!important',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.08)',
  backdropFilter: 'blur(14px)',
  transition: theme.transitions.create(
    ['background-color', 'backdrop-filter', 'box-shadow'],
    {
      duration: theme.transitions.duration.standard,
    }
  ),
  [theme.breakpoints.up('md')]: {
    backgroundColor: isTransparentBackground
      ? theme.palette.appBarTransparentBackground
      : theme.palette.background.white,
    backdropFilter: isTransparentBackground ? 'blur(4px)' : 'initial',
    height: appBarHeight.md,
    minHeight: appBarHeight.md,
    boxShadow: isTransparentBackground
      ? 'none'
      : '0px 2px 8px rgba(0, 0, 1, 0.1)',
  },
}))
