import { Stack, SxProps } from '@mui/material'

import { LanguageSwitcher } from '@features/app-bar'

import { StyledStack } from '../styled'

interface IProps {
  sx?: SxProps
  isTransparentBackground: boolean
}

export default function LangMenu({ sx, isTransparentBackground }: IProps) {
  return (
    <StyledStack
      direction="row"
      alignItems="center"
      justifyContent="flex-end"
      sx={sx}
    >
      <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, md: 2 }}>
        <LanguageSwitcher isTransparentBackground={isTransparentBackground} />
      </Stack>
    </StyledStack>
  )
}
