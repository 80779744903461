import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

export const LevelDesc = styled(Typography)(({ theme }) => ({
  fontFamily: 'Inter',
  fontSize: 14,
  fontWeight: 400,
  color: theme.palette.colorShadesKit.csk800,
  lineHeight: '18px',
  width: 246,
  marginRight: 20,
}))
