import { createRef } from 'react'
import { CustomRouteObject } from 'react-router-dom'

import { routeNamesPaths } from '@shared/constants'

export const ArbiexPageRoute: CustomRouteObject = {
  path: routeNamesPaths.arbiex,
  lazy: async () => {
    const { ArbiexPage } = await import('./ui')

    return {
      Component: ArbiexPage,
    }
  },
  nodeRef: createRef(),
}
