import * as dateFnsLocales from 'date-fns/locale'
import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import { en, sk, cz } from './translations'

export const languageLabels = {
  en: 'En',
  sk: 'Sk',
  cz: 'Cz',
} as const

export const languages = Object.keys(
  languageLabels
) as (keyof typeof languageLabels)[]

export type TLanguages = keyof typeof languageLabels

interface Locales {
  [key: string]: Locale
}

export const getDateFnsLocale = (): Locale => {
  const locales: Locales = {
    en: dateFnsLocales.enGB,
    sk: dateFnsLocales.sk,
    cz: dateFnsLocales.cs,
  }

  return locales[i18n.language]
}

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: true,
    },
    resources: {
      en,
      sk,
      cz,
    },
    defaultNS: '',
    supportedLngs: ['en', 'sk', 'cz'],
    react: {
      transSupportBasicHtmlNodes: true,
    },
  })

export default i18n
