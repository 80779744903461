import { Fragment } from 'react'
import { Helmet } from 'react-helmet'
import { ScrollRestoration } from 'react-router-dom'

import { ErrorPage } from '@pages/error'
import { AppBar } from '@widgets/app-bar'
import { Footer } from '@widgets/footer'
import { appBarHeight } from '@shared/constants'

export function ErrorLayout() {
  window.document.body.style.overflowY = 'scroll'

  return (
    <Fragment>
      <Helmet
        encodeSpecialCharacters={true}
        defaultTitle="ARBITAS SICAV INVESTMENT FUND │ A bridge between technology and finance"
        titleTemplate="%s - ARBITAS SICAV INVESTMENT FUND │ A bridge between technology and finance"
      />

      <AppBar />

      <ErrorPage
        sx={{ mt: { xs: `${appBarHeight.xs}px`, md: `${appBarHeight.md}px` } }}
      />

      <Footer />
      <ScrollRestoration />
    </Fragment>
  )
}
