import { Box, styled } from '@mui/material'

import { getCurrentBrowser } from '@shared/utils'

import BannerBgMobilePNG from '../../../assets/banner-bg-mobile.png'
import BannerBgMobileWebP from '../../../assets/banner-bg-mobile.webp'
import BannerBgPNG from '../../../assets/banner-bg.png'
import BannerBgWebP from '../../../assets/banner-bg.webp'

export const StyledBannerBox = styled(Box)(({ theme }) => ({
  padding: '40px 16px',
  borderRadius: theme.spacing(2.5),
  backgroundImage: `url(${
    getCurrentBrowser() === 'Firefox' ? BannerBgMobilePNG : BannerBgMobileWebP
  })`,
  backgroundSize: 'cover',
  [theme.breakpoints.up('md')]: {
    padding: '32px 16px',
    backgroundImage: `url(${
      getCurrentBrowser() === 'Firefox' ? BannerBgPNG : BannerBgWebP
    })`,
  },
}))
